/**
 * Components Utility Functions
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Declare utils namespace.
Components.utils = {};

// Breakpoint values.
Components.utils.breakpoints = {
  mobileMax: 639,
  tabletMin: 640,
  tabletMax: 960,
  desktopMin: 961,
  contentMax: 1550,
  layoutMax: 1920
};

/**
 * Smooth Scroll to top of an element
 *
 * @param  {jQuery} $element
 *   Element to scroll to the top of
 * @param  {number} [offset]
 *   Any offset to account for sticky elements.
 * @param  {boolean} [onlyUp]
 *   Whether scroll should only happen if the scroll direction is up.
 */
Components.utils.smoothScrollTop = function ($element, offset = 0, onlyUp = false) {
  const elementTop = $element.offset().top;
  const pageTop = $(window).scrollTop();

  if (!onlyUp || (onlyUp && pageTop > elementTop)) {
    window.scrollTo({
      top: elementTop - offset,
      behavior: 'smooth'
    });
  }
};

/**
 * Get parsed URL params, with caching.
 *
 * @return {Object} URL Params
 */
Components.utils.getUrlParams = function () {
  var urlParams = Components.utils.parseUrlParams;
  // Return the cached result, or on cache miss, the result of the invoked
  // function, assigned to the cache property of this Function object.
  return urlParams.cache || (urlParams.cache = urlParams());
};

/**
 * Get parsed URL params.
 *
 * @return {Object} URL Params
 */
Components.utils.parseUrlParams = function () {
  var query = window.location.search.substring(1);
  var result = {};
  var match;
  var search = /([^&=]+)=?([^&]*)/g;
  var decode = function (s) {
    // This needs a try catch because we may get bad param values like
    // &dclid=%edclid! which look like a percent-encoded uri component
    // but isn't and will error.
    try {
      return decodeURIComponent(s.replace(/\+/g, ' '));
    } catch (e) {
      return '';
    }
  };

  while ((match = search.exec(query)) !== null) {
    if (decode(match[1]) && decode(match[2])) {
      result[decode(match[1])] = decode(match[2]);
    }
  }

  return result;
};


/**
 * Helper to identify which breakpoint the browser is in.
 * @param  {string} layout - the layout mode to check for.
 * @return {Boolean} whether viewport is within specified breakpoint
 * @example Components.utils.breakpoint('mobile') - true if in mobile layout
 */
Components.utils.breakpoint = function (layout) {
  // Fail fast if matchMedia isn't present.
  if (typeof window.matchMedia !== 'function') {
    return false;
  }

  switch (layout) {
    case 'mobile':
      return matchMedia('(max-width: ' + Components.utils.breakpoints.mobileMax + 'px)').matches;
      break;
    case 'tablet':
      return matchMedia('(min-width:' + Components.utils.breakpoints.tabletMin + 'px) and (max-width: ' + Components.utils.breakpoints.tabletMax + 'px)').matches;
      break;
    case 'desktop':
      return matchMedia('(min-width: ' + Components.utils.breakpoints.desktopMin + 'px)').matches;
      break;
    default:
      return false;
  }
};

/**
 * Return the matching breakpoint name (mobile, tablet, or desktop).
 * Similar to Components.utils.breakpoint but returns the active breakpoint name instead.
 *
 * @todo refactor all existing usages of Components.utils.breakpoint to use this function.
 *
 * @returns {String}
 */
Components.utils.getBreakpoint = function () {
  var isTablet,
      isDesktop;

  // Fail fast if matchMedia isn't present. Assume desktop.
  if (typeof window.matchMedia !== 'function') {
    return 'desktop';
  }

  isTablet = matchMedia(
    '(min-width:' + Components.utils.breakpoints.tabletMin + 'px) and (max-width: ' +
    Components.utils.breakpoints.tabletMax + 'px)'
  ).matches;

  isDesktop = matchMedia(
    '(min-width: ' + Components.utils.breakpoints.desktopMin + 'px)'
  ).matches;

  if (isDesktop) {
    return 'desktop';
  }
  else if (isTablet) {
    return 'tablet';
  }
  else {
    return 'mobile';
  }
};

/**
 * Helper function to get the element's viewport center.
 * @param $element
 *
 * @returns string
 *  y position
 */
Components.utils.getElementViewPortCenter = function ($element) {
  var scrollTop = $(window).scrollTop(),
    scrollBot = scrollTop + $(window).height(),
    elHeight = $element.outerHeight(),
    elTop = $element.offset().top,
    elBottom = elTop + elHeight,
    elTopOffset = elTop < scrollTop ? scrollTop - elTop : 0,
    elBottomOffset = elBottom > scrollBot ? scrollBot - elTop : elHeight;

  // Return 50% if entire element is visible.
  if (elTopOffset === 0 && elBottomOffset === elHeight) {
    return '50%';
  }

  return Math.round(elTopOffset + ((elBottomOffset - elTopOffset) / 2)) + 'px';
};

/**
 * Helper function to decide the next logical heading level.
 *
 * In order to maintain best practices for A11y, the document structure should be in order.
 * See https://www.w3.org/WAI/tutorials/page-structure/headings/.
 * This can be useful for programmatically setting the next heading level
 * for an element or group of elements. See jquery.accordion.js as example.
 * @param {jQuery Object} $startElement - The element where we start the traverse.
 *
 * @returns {string|null}
 *  H1 - H6 to insert as a tag.
 */
Components.utils.setNextHeadingLevelA11y = function ($startElement) {
  if (!$startElement.length) {
    return null;
  }
  // Recursive function to find the closest previous headline.
  function findPreviousHeadline($element) {
    // If we've reached the body or html, there are no more elements to check.
    if ($element.is('body') || $element.is('html')) {
      return null;
    }

    // First check if there are any immediate headline siblings.
    let $found = $element.prevAll('h1,h2,h3,h4,h5,h6').first();
    if ($found.length) {
      return $found;
    }
    // Otherwise check inside siblings for nested headlines except the accordion
    // content wrapper. This is to prevent the nested accordion contents from
    // being considered when determining the headline structure.
    let $foundNested = $element.prevAll().filter(function() {
      return $(this).find('.accordion__content-wrapper').length === 0;
    }).find('h1,h2,h3,h4,h5,h6').last();
    if ($foundNested.length) {
      return $foundNested;
    }

    // If no previous sibling or headline found, move up to the parent.
    return findPreviousHeadline($element.parent());
  }

  // Start the search from the provided element.
  const $previousHeadline = findPreviousHeadline($startElement);
  // Determine the next headline level based on the found headline.
  if ($previousHeadline) {
    const currentLevel = parseInt($previousHeadline.prop('tagName').substring(1), 10);
    if (currentLevel < 6) {
      // Next level, if current is less than h6
      return `h${currentLevel + 1}`;
    }
    // Stay at h6 if it's already the deepest level.
    return 'h6';
  }
  // This could default to h1, but to be safe, default to h2 since this will
  // most likely be components on the page and not the main headline.
  return 'h2';
};

/**
 * Do a non-jQuery based height animation.
 *
 * @param {HTMLElement} element
 *   The HTML element to transition.
 * @param {Object} options
 *   Options for the height transition.
 * @param {number} [options.startHeight=0]
 *   The `start` height in pixels. Defaults to 0.
 * @param {number} [options.endHeight=computed height]
 *   The `end` height in pixels. Defaults to the computed height of the element.
 * @param {number} [options.duration=500]
 *   The duration of the height transition in milliseconds. Defaults to 500ms.
 *
 * @return {Promise<void>}
 *   Resolves when the transition has ended.
 */
Components.utils.transitionHeight = function (element, options = {}) {
  return new Promise((resolve) => {
    const {
      startHeight = 0,
      endHeight = parseFloat(getComputedStyle(element).height),
      duration = 500
    } = options;

    // Disable height transitioning for the forced reflow part below.
    element.style.transition = '';
    element.style.height = `${startHeight}px`;
    // Forces reflow to apply `startHeight` immediately, ensuring smooth transition.
    if (element.offsetHeight) {
      // This `if` is empty — we only needed to access `offsetHeight` above.
    }
    // (Re-)enable height transitioning with the specified duration.
    element.style.transition = `height ${duration}ms ease-in-out`;
    element.style.height = `${endHeight}px`;

    setTimeout(resolve, duration);
  });
};

/**
 * Accordion Utility
 *
 * Used for creating a list of expandable content in which only one item of
 * content is expanded at a time.
 *
 * Settings:
 *   itemSelector - Required - [string] - CSS selector for item wrappers
 *   headerSelector - Required - [String] - CSS selector for header elements
 *     that will be used to open/close accordion items when clicked
 *   contentSelector - Required - [String] - CSS selector for contents elements
 *     that will be hidden or shown when headers are clicked.
 *
 * Usage:
 *   $('.accordion').accordion({
 *     itemSelector: '.accordion__item',
 *     headerSelector: '.accordion__header',
 *     contentSelector: '.accordion__contents'
 *   });
 *
 *  Available Methods:
 *    showItem - Show the specified item and collapse all others.
 *        Ex. $('.selector')[0].accordion.showItem($item);
 *    hideItem - Hide any open item within the accordion.
 *        Ex. $('.selector')[0].accordion.hideItem();
 *    setA11y - Set A11y attributes & structure.
 */

(function ($) {

  // Set plugin method name and defaults
  const pluginName = 'accordion',
      defaults = {};

  // Plugin constructor
  function Plugin (element, settings) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.$element = $(element);

    // Use defaults for any unassigned settings.
    this.settings = { ...defaults, ...settings };

    // Set several fixed global settings.
    // `open` class is used for legacy support.
    this.settings.openClasses = 'is-open open';
    this.settings.openSelector = '.is-open, .open';

    // Do initial setup stuff.
    this.init();
  }

  /**
   * Open the specified item in the accordion and manage closing other items.
   *
   * @param {jQuery Object} $item - The specific item to be opened.
   * @param {jQuery Object} $items - All items within the accordion.
   */
   Plugin.prototype.openAccordion = function ($item) {
    const $items = this.$element.find(this.settings.itemSelector),
          $otherItems = $items.not($item);

    // First make sure other open items are closed.
    this.hideItems($otherItems);

    // Then open/close the clicked item.
    if ($item.is(this.settings.openSelector)) {
      this.hideItem($item);
    } else {
      this.showItem($item);
    }

    // Trigger custom event for external scripts to know when an accordion is
    // interacted with.
    this.$element.trigger('accordion:after');
  };

  /**
   * Show the contents of the specified item
   *
   * @param {jQuery Object} $item - The item to show the contents of.
   */
  Plugin.prototype.showItem = function ($item) {
    // Note: The open classes are required on the content selector.
    // Keeping the addition of the open class on the item for legacy support.
    $item.addClass(this.settings.openClasses);
    $item.find(this.settings.contentSelector).addClass(this.settings.openClasses);
    $item.find('button.accordion__title').attr('aria-expanded', 'true');
  };

  /**
   * Hide the contents of the specified item.
   *
   * @param {jQuery Object} $item - The item to hide the contents of.
   */
  Plugin.prototype.hideItem = function ($item) {
    // Note: The open classes are required on the content selector.
    // Keeping the removal of the open class on the item for legacy support.
    $item.removeClass(this.settings.openClasses);
    $item.find(this.settings.contentSelector).removeClass(this.settings.openClasses);
    $item.find('button.accordion__title').attr('aria-expanded', 'false');
  };

  /**
   * Hide any open items passed in.
   *
   * @param {jQuery Object} $items - The set of items to close if open.
   */
  Plugin.prototype.hideItems = function ($items) {
    const _this = this;

    $items.each(function (index, item) {
      if ($(item).is(_this.settings.openSelector)) {
        _this.hideItem($(item));
        $(item).find('button.accordion__title').attr('aria-expanded', 'false');
      }
    });
  };

  /**
   * Set up accessibility attributes & structure.
   *
   * @param {jQuery Object} $items - All items within the accordion.
   **/
  Plugin.prototype.setA11y = function ($items) {
    // In order to adhere to A11y guidelines for interactive content and keyboard navigation,
    // we should be using a button element which has native keyboard interaction.
    const isUsingButton = $items.find('button[aria-expanded]').length;
    const $enhanceEl = $items.find(':not([aria-expanded])');
    if (!isUsingButton) {
      $enhanceEl.find('.accordion__title').replaceWith(function() {
        // In the case accordion__title element has child elements, remove inner
        // headline and update divs to spans. Inside <button>: Only phrasing content
        // allowed. This also avoids odd headline structure when we are
        // wrapping the button with a headline programmatically.
        if ($(this).find('h1, h2, h3, h4, h5, h6, p').length > 0) {
          $(this).find('h1, h2, h3, h4, h5, h6, p').replaceWith(function() {
            return $(this).text();
          });
        }
        if ($(this).find('div').length > 0)  {
          $(this).find('div').each(function() {
            // Keep attributes if there are any.
            const attr = $(this).prop("attributes");
            const span = $('<span></span>');
            if (attr) {
              $.each(attr, function() {
                span.attr(this.name, this.value);
              });
              $(this).replaceWith(span.html($(this).html()));
            }
            // Preserve formatting with display-block when we
            // change divs to spans in the accordion button.
            span.addClass('display-block');
          })
        }
        return `<button class="accordion__title">${ $(this).html() }</button>`
      });
    }

    const _this = this;
    const $accordionButton = $('button.accordion__title');
    const getNextHeadingLevel = Components.utils.setNextHeadingLevelA11y($('.accordion'));
    $items.each(function (index){
      // ARIA attributes for screen readers. Set a unique ID for each accordion__item.
      // Set aria-labelledby to give the role="region" an accessible name.
      const randomID = Math.floor(Math.random()  * (10000 - index) + index);
      const panelID = 'panel-' + randomID;
      const buttonID = 'accordion-' + randomID;
      $(this).find($accordionButton).attr({ 'id': buttonID, 'aria-controls':panelID, 'aria-expanded':'false' });
      $(this).find('.accordion__content-wrapper').attr({ 'id':panelID, 'aria-labelledby':buttonID, 'role':'region' });
      // If the accordion is open by default, set aria-expanded="true".
      $(this).filter(_this.settings.openSelector).find($accordionButton).attr('aria-expanded', 'true');

      // The following sets the button wrapper to the next logical heading level
      // to align with A11y best practices for document structure.
      const newHeadingEl = document.createElement(`${getNextHeadingLevel}`);
      // The accordion headers should be styled as H5.
      newHeadingEl.classList.add('heading--h5');
      // Insert new heading as parent of the button.
      $(this).find('.accordion__title-wrapper').prepend(newHeadingEl);
      $(this).find($accordionButton).prependTo(newHeadingEl);
    })
  }

  // Initial setup tasks
  Plugin.prototype.init = function () {
    const _this = this,
        $items = this.$element.find(_this.settings.itemSelector),
        hash = window.location.hash;

    // Setup accessibility attributes and structure.
    this.setA11y($items);

    // Initially hide contents of all items, except those specified as open by
    // default in the markup.
    $items.not(_this.settings.openSelector).find(_this.settings.contentSelector).removeClass(this.settings.openClasses);

    // Items that are specified as open by default, add is-open class to content for height transition.
    $items.filter(_this.settings.openSelector).find(_this.settings.contentSelector).addClass(this.settings.openClasses);

    // Handle toggling overflow property transitioning.
    // - When open, and the open transition has ended, show overflow content.
    // - When closing, and a height transition is starting, hide overflow content.
    this.$element.on('transitionstart transitionend', (e) => {
      const $target = $(e.target);
      if ($target && e.originalEvent.propertyName === 'visibility') {
        const openClasses = _this.settings.openClasses.split(/\s+/);
        const isOpen = openClasses.some((className) => $target.hasClass(className));
        const $targetInner = $target.find('.accordion__content');
        // Since the move from jQuery transitions to css based transitions, accordion content is set
        // to overflow:hidden, which ended up causing a regression in instances where elements inside the accordion
        // were absolutely positioned. This allows all inner elements to be visible when the accordion is open.
        if (isOpen && e.type === 'transitionend') {
          $targetInner.css('overflow', 'unset');
        }
        else if (!isOpen && e.type === 'transitionstart') {
          $targetInner.css('overflow', 'hidden');
        }
      }
    });

    // Handle showing an accordion item when its heading is clicked.
    this.$element.find(_this.settings.headerSelector).on('click.accordion', function (e) {
      if ($(e.target).is('a')) {
        // If the click is happening on a link inside the header,
        // do not open accordion, open link instead.
        return true;
      }
      _this.openAccordion($(this).closest(_this.settings.itemSelector));
      e.preventDefault();
    });

    // Expand accordion items when linked to with a hash.
    if ($(hash).length && _this.$element.find($(hash)).length) {
      const $item = $(hash).is(_this.settings.itemSelector) ? $(hash) : $(hash).closest(_this.settings.itemSelector);

      _this.openAccordion($item);
    }

  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (settings) {
    return this.each(function initPlugin() {
      let plugin;

      if (!$.data(this, 'plugin_' + pluginName)) {
        plugin = new Plugin(this, settings);
        $.data(this, 'plugin_' + pluginName, plugin);

        // Expose the plugin so methods can be called externally
        // E.g.: element.accordion.openAccordion();
        this.accordion = plugin;
      }
    });
  };
})(jQuery);

/**
 * Content Flyout utility.
 *
 * Set up a content region that is hidden by default and "flies out" from the
 * right side of the page when a trigger is clicked.
 *
 * Usage Examples. See options described in detail below.
 *
 * $('.flyout-content-wrapper').contentFlyout(flyoutOptions);
 *
 * $('.flyout-content-wrapper').contentFlyout({
 *   triggers: $('.triggers-selector'),
 *   slideout: $pageWrapper,
 * });
 */

/**
 * @typedef {object} flyoutOptions
 * @property {jQuery} triggers
 *   element(s) to be used as a trigger.
 * @property {jQuery} slideout
 *   element to slide off screen when content flies in.
 * @property {jQuery} [contents]
 *   element(s) to use as content wrapper.
 * @property {jQuery} [closeLinks]
 *   element(s) to be used to trigger closing flyout (optional).
 * @property {boolean} [scroll]
 *    whether the page should scroll up to the flyout content if needed (optional).
 * @property {object} [closeLinks]
 *   animation settings for expanding/collapsing (optional).
 */
(function ($) {

  // Set plugin method name and defaults
  var pluginName = 'contentFlyout',
      defaults = {
        animation: {
          duration: 500,
          easing: "easeInOutQuart"
        },
        scroll: true,
      };


  // plugin constructor
  function Plugin (element, options) {
    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this.element = $(element);

    // Use the init options.
    this.options = $.extend({}, defaults, options);

    // Do it now.
    this.init();

    // Fly out content automatically if there's a matching hash in the URL.
    this.autoReveal();
  }

  /**
   * Show the target content based on the specified $trigger
   *
   * @param {jQuery Object} $trigger - The trigger link corresponding to the
   * content to be displayed
   * @param {Object} settings - Additional options to override defaults including:
   *          {Object} animation - overrides to the default animation settings
   *          {Boolean} scroll - Whether or not to scroll to the conent
   *          {Boolean} scrollDown - Whether or not clicking this trigger should
   *            scroll down to the top of the flyout content container.
   */
  Plugin.prototype.showContent = function(trigger, settings) {
    var data = $(trigger).length ? $(trigger).data() : {},
        $target = data.flyoutTarget ? $('#' + data.flyoutTarget) : this.element,
        $parent = $target.offsetParent(),
        $slideout = $parent.find(this.options.slideout),
        href = $(trigger).length ? $(trigger).attr('href') : '',
        parentPadding = $parent.outerHeight() - $parent.height(),
        offset = $('.sticky-wrapper').outerHeight(true),
        defaultSettings = {
          scroll: this.options.scroll,
          scrollDown: data.flyoutScrollDown,
          animation: this.options.animation
        };

    // Merge settings with defaults.
    settings = $.extend({}, defaultSettings, settings);

    $target.data('flyoutState', 'open');

    // Adjust height of parent, as long as it's not the body or html element.
    if (!$parent.is('body, html')) {
      const startHeight = $parent.height();
      const endHeight = $target.outerHeight(true) - parentPadding;
      Components.utils.transitionHeight($parent[0], { startHeight, endHeight });
    }

    // Slide out Animation is controlled by css animation.
    $target.add($slideout).addClass('is-open');

    if (settings.scroll) {
      Components.utils.smoothScrollTop($parent, offset, !settings.scrollDown);
    }

    // Push the current state to the URL
    if ((href.indexOf('#') === 0) && (href.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, href);
    }
  };

  // Hide the target content
  Plugin.prototype.hideContent = function(trigger) {
    var data = $(trigger).length ? $(trigger).data() : {},
        $target = data.flyoutTarget ? $('#' + data.flyoutTarget) : this.element,
        $parent = $target.offsetParent(),
        $slideout = $parent.find(this.options.slideout);

    $target.data('flyoutState', 'closed');

    // Adjust height of parent, as long as it's not the body or html element.
    if (!$parent.is('body, html')) {
      const startHeight = $parent.height();
      const endHeight = $slideout.outerHeight(true);
      Components.utils.transitionHeight($parent[0], { startHeight, endHeight })
        .then(() => {
          $parent.height('inherit');
        });
    }

    // Slide out Animation is controlled by css animation.
    $target.add($slideout).removeClass('is-open');

    // Remove the current state from the URL
    if ((window.location.hash.indexOf('#') === 0) && (history.replaceState)) {
      history.replaceState(undefined, undefined, window.location.pathname);
    }
  };

  // Automatically reveal content when the ID of the container is in the URL
  // hash.
  Plugin.prototype.autoReveal = function() {
    var hash = window.location.hash,
        $trigger;

    // Avoid colliding with flyout form behavior.
    if (hash === "#form") {
      return;
    }

    // If the hash exists (e.g. #something) and it matches using jQuery selection.
    if (hash.length > 1 && this.element.is(hash)) {
      $trigger = $(hash).data('flyoutTrigger');

      // Prevent scrolling to the anchor...
      setTimeout(function() {
        window.scrollTo(0, 0);
      }, 1);

      this.showContent($trigger, {
        animation: {duration: 0},
        scroll: true,
        scrollDown: true
      });
    }
  };

  // Hand-full of setup tasks
  Plugin.prototype.init = function () {
    var _this = this,
        $triggers = $();

    // Link content back to its corresponding trigger
    _this.options.triggers.each(function(index, el) {
      var $trigger = $(this),
          targetId = $trigger.data('flyoutTarget'),
          $target = $('#' + targetId);

      // Only pay attention if the target is the correct one.
      if (_this.element.is($target)) {
        $triggers = $triggers.add($trigger);
        $target.data('flyoutTrigger', $trigger);

        // Set the trigger link's href if it isn't already set, excluding "#".
        if ($trigger.attr('href').length <= 1) {
          $trigger.attr('href', '#' + targetId);
        }
      }
    });

    if ($triggers.length && _this.element.length) {
      // Add flyout-state data
      _this.element.data('flyoutState', 'closed');

      // Set the relative parent to hide overflow
      _this.element.each(function(index, el) {
        var $offsetParent = $(this).offsetParent();
        // The following segment looks odd but it is to avoid using particularly
        // slow jQuery methods including .show()
        this.style.display = '';

        if (!$offsetParent.is('body, html')) {
          $offsetParent.css('overflow', 'hidden');
        }
        else {
          $offsetParent.css('overflow-x', 'hidden');
        }
      });

      // Handle opening the flyout when a trigger is clicked.
      $triggers.on('click.flyout', function(e) {
        var trigger = this,
            $target = $('#' + $(trigger).data('flyoutTarget')),
            state = $target.data('flyoutState');

        if (state === 'closed') {
          setTimeout(function() {
            _this.showContent(trigger);
          }, 1);
        }
        e.preventDefault();
      });

      // Handle closing the flyout when a close link is clicked.
      _this.options.closeLinks.on('click.flyout', function(e) {
        var $parent = $(this).closest(_this.element),
            state = $parent.data('flyoutState');

        // Double-check that the flyout is open and it's the correct flyout.
        if (_this.element.is($parent) && state === 'open') {
          _this.hideContent($parent.data('flyoutTrigger'));
        }
        e.preventDefault();
      });
    }
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      var plugin = new Plugin(this, options);
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   Ex. $(element).contentFlyout.showContent();
      this.contentFlyout = plugin;
    });
  };
})(jQuery);

/**
 * @file contentReveal - A jQuery plugin for revealing content with show/hide transition.
 *
 * While primarily used on www.tableau.com to reveal large video players, this plugin is
 * versatile enough to reveal any type of content. The reveal uses a clever
 * `grid-template-rows` transition for animating between 0 and "auto" heights using a
 * height transition from 0 to 1fr (100% of the natural height).
 *
 * Custom events are triggered when the reveal content opens or closes. Event listeners
 * can hook into these to extend the functionality. Details below.
 *
 * General usage:
 *
 * ```javascript
 * $('.reveal__content').contentReveal();
 * ```
 **
 * Custom events provided for extension:
 *
 * @fires `reveal:open`
 *   Triggered when the reveal content opens. Event handlers receive the plugin instance.
 * @fires `reveal:close`
 *   Triggered when the reveal content closes. Event handlers receive the plugin instance.
 *
 * @example
 * $('.reveal__content').on('reveal:open', function (event, instance) {
 *     // Custom behavior on reveal open (e.g., play video)
 * });
 *
 * Accessibility applied to trigger elements, reveal and curtain elements:
 * - `aria-expanded`: Indicates the expanded or collapsed state of the reveal element.
 * - `aria-hidden`: Controls the visibility state of the reveal content for screen readers.
 * - `inert`: Used to prevent interaction with hidden content (when supported by browsers).
 */

(function ($) {
  const pluginName = 'contentReveal';

  /**
   * The `contentReveal` jQuery plugin constructor.
   *
   * @alias $.fn.contentReveal.Plugin
   * @constructor
   * @param {HTMLElement} element - The DOM element to which the plugin is attached.
   *
   * @property {jQuery} $reveal - The main content element that will be revealed.
   * @property {jQuery} $curtain - The curtain element, adjacent or parent to the reveal, generally used to cover or hide content before reveal.
   * @property {jQuery} $triggers - One or more trigger elements that initiate the reveal when clicked.
   * @property {jQuery} $mostRecentTrigger - The most recent trigger element that initiated the last reveal action.
   * @property {jQuery} $closeButton - The button element used to close the reveal content.
   * @property {boolean} isOpen - Boolean flag indicating whether the reveal content is currently open.
   */
  function Plugin (element) {
    const $empty = $();
    this.$reveal = $(element);
    this.$triggers = $empty;
    this.$mostRecentTrigger = $empty;
    this.$curtain = $empty;
    this.$closeButton = $empty;
    this.isOpen = false;
    this.init();
  }

  /**
   * Open the reveal.
   *
   * Reveals the content, applying necessary animations and updating ARIA attributes.
   * Updates the URL hash, and fires the custom `reveal:open` event.
   *
   * @alias $.fn.contentReveal.Plugin#open
   * @fires `reveal:open` - a jQuery custom event -- passes the plugin instance object.
   */
  Plugin.prototype.open = function () {
    const { $curtain, $triggers, $reveal, $closeButton } = this;

    this.isOpen = true;
    $curtain.removeClass('is-open');
    $reveal.addClass('is-open');

    // A11y attributes for assistive tech.
    // All triggers should have the same `aria-expanded` value.
    $triggers.attr('aria-expanded', true);
    $reveal.attr('aria-hidden', false).prop('inert', false);
    $curtain.prop('inert', true);

    // Focus on the button to make it easily accessible for users navigating via keyboard
    // or assistive technologies, so they don't need to search for the control.
    $closeButton.focus();

    // Let other behaviors hook into the open event.
    $reveal.trigger('reveal:open', [ this ]);

    // Update the URL with the reveal ID hash.
    const newUrl = window.location.href.split('#')[0] + `#${$reveal.attr('id')}`;
    window.history.replaceState(null, null, newUrl);
  };

  /**
   * Close the reveal.
   *
   * Hides the content, applies necessary animations, and updates ARIA attributes.
   * Fires the custom `reveal:close` event.
   *
   * @alias $.fn.contentReveal.Plugin#close
   * @fires `reveal:close` - a jQuery custom event -- passes the plugin instance object.
   */
  Plugin.prototype.close = function() {
    const { $reveal, $curtain, $triggers, $mostRecentTrigger } = this;

    // A11y attributes for assistive tech.
    // All triggers should have the same `aria-expanded` value.
    $triggers.attr('aria-expanded', 'false');
    $reveal.attr('aria-hidden', true).prop('inert', true);
    $curtain.prop('inert', false);
    $mostRecentTrigger.focus();

    this.isOpen = false;
    $reveal.removeClass('is-open');
    $curtain.addClass('is-open');
    // Let other behaviors hook into the close event.
    $reveal.trigger('reveal:close', [ this ]);

    // Update the URL to remove the hash.
    const newUrl = window.location.href.split('#')[0];
    window.history.replaceState(null, null, newUrl);
  };

  /**
   * Automatically reveal content if the element's ID matches the window hash.
   *
   * This is useful for deep linking directly to specific content on page load.
   *
   * @alias $.fn.contentReveal.Plugin#autoReveal
   */
  Plugin.prototype.autoReveal = function () {
    // Includes the #.
    const rawHash = window.location.hash;
    // Remove the # symbol.
    let hash = rawHash.substring(1);
    const { $reveal } = this;

    // Ensure the hash is properly formatted. When there's no hash,
    // window.location.hash is an empty string (""), making hash.length === 0.
    // If the hash exists (e.g. #something) the substring(1) ensures hash is
    // "something", not "#something". At the same time, it ensures any
    // valid hash (even short ones) works, while also preventing
    // empty hashes("") from causing issues.
    if (hash.length > 0) {
      try {
        // Escape the cleaned hash.
        const safeHash = jQuery.escapeSelector(hash);
        // Add # manually.
        const element = document.querySelector(`#${safeHash}`);

        if (element && $reveal.is(`#${safeHash}`)) {
          this.open();
          const scrollOffset = $('.sticky-wrapper .stuck').outerHeight(true) || 0;
          Components.utils.smoothScrollTop($reveal, scrollOffset);
        }
      } catch (error) {
        // Silently fail, doing nothing.
      }
    }
  };

  /**
   * Initialize the plugin.
   *
   * Sets up the DOM structure, event listeners, and ensures accessibility by applying
   * ARIA attributes for open/close state.
   *
   * @alias $.fn.contentReveal.Plugin#init
   */
  Plugin.prototype.init = function () {
    // Find all triggering elements for this reveal id.
    const $triggers = $(`[data-reveal-target="${this.$reveal.attr('id')}"]`);
    // Abort if no triggers were found. This is invalid markup.
    if (!$triggers.length) {
      return;
    }
    this.$triggers = $triggers;
    // Use the first trigger's data set.
    // Note: this is for legacy reasons. We assume the same curtain is used for all
    // triggers.
    const data = $triggers.data();
    const $curtain = $(`#${data.revealCurtain}`)
    this.$curtain = $curtain;

    const { $reveal } = this;

    // Wrap element with a special wrapper for transitioning height with CSS.
    $reveal.add($curtain).addClass('transition-height__inner').wrap('<div class="transition-height"></div>');
    $curtain.addClass('is-open');

    // Add an accessible close button.
    const $closeButton = $('<button class="reveal__close"><i aria-label="close" class="icon icon--close-window-style2"></i></button>');
    this.$closeButton = $closeButton;
    // Add a custom aria-label to the icon if the data-attribute exists to give the button an accessible name.
    // Using data-close-label attribute in the template allows for the label to be translatable.
    const $closeButtonIcon = $reveal.find('button.reveal__close i');
    const $closeLabelElement = $reveal.find('[data-close-label]');
    if ($closeButtonIcon.length && $closeLabelElement.length) {
      $closeButtonIcon.attr('aria-label', $closeLabelElement.data('closeLabel'));
    }
    $reveal.prepend($closeButton);

    $triggers.attr('aria-expanded', 'false');

    $triggers.on('click.reveal', (e) => {
      e.preventDefault();
      this.$mostRecentTrigger = $(e.target);
      if (!this.isOpen) {
        this.open();
      } else {
        this.close();
      }
    });

    $closeButton.on('click.reveal', (e) => {
      e.preventDefault();
      this.close();
    });

    // Reveal content automatically if there's a matching hash in the URL.
    this.autoReveal();

    // Add `is-initialized` class to hide while the page loads + support close animation.
    this.$reveal.addClass('is-initialized');
  };

  /**
   * jQuery plugin definition.
   */
  $.fn[pluginName] = function () {
    return this.each(function initializePlugin() {
      const plugin = new Plugin(this);
      // Expose the instance on the html element's dataset as `plugin_<pluginName>`.
      $.data(this, 'plugin_' + pluginName, plugin);
      // Expose the Plugin constructor for debuggability, IDE code references, etc.
      $.fn.contentReveal.Plugin = Plugin;
    });
  };
})(jQuery);

'use strict';

/**
 * jQuery Dynamic Select Filters
 *
 * Given a set of input radio options, generate a corresponding select list per
 * option group and binds change events so that using the select triggers the
 * original option inputs, which may now be hidden.
 *
 * The javascript init, with options thrown in:
 *

  $('.filter-set').dynamicSelectFilters({
    container: '.mobile-filter-set',
    groupHeading: '.filter-set__heading',
    onCreateSelectCallback: function () {
      // 'this' is the jQuery wrapped select element, created per group set.
      this.wrap('<div class="form-field__wrapper"><div class="form__select"></div></div>');

      // Perform additional event bindings as needed.
      this.on('click.namespace', function myCoolEvent(e) {
        doMyThings();
      });
    }
  });

 */
(function ($) {
  // Set plugin method name and defaults
  var pluginName = 'dynamicSelectFilters',
      defaults = {
        // A DOM selector of the container to place the dynamic <select> elements.
        // If not defined, one will be generated and placed before the first
        // option group found.
        container: false,
        // An optional DOM selector to provide a default option in the select
        // element. Should be located inside the grouping DOM element.
        groupHeading: '',
        // Callback function after each select is created. Passes in the newly
        // created select jQuery element to perform any additional modifications.
        onCreateSelectCallback: null
      };

  // plugin constructor
  function Plugin (element, options) {
    var $element = $(element);

    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this._element = $element;

    // Use the init options.
    this.options = $.extend(true, defaults, options);

    // Do it now.
    this.init();
  }

  Plugin.prototype.init = function () {
    var _options = this.options,
        $radioGroups = this._element,
        $selectContainer = $radioGroups.find(_options.container);

    if (!$radioGroups.length) {
      return;
    }

    // If no container for the select is defined, add one.
    if (!$selectContainer.length) {
      $radioGroups.eq(0).before('<div class="dynamic-select-container"></div>');
      $selectContainer = $radioGroups.eq(0).prev('.dynamic-select-container');
    }

    $radioGroups.each(function initSelectDuplication() {
      var $this = $(this),
          // Grouping label, generated as a disabled option within the select to
          // act as a label.
          groupHeading = $this.find(_options.groupHeading),
          $input = $this.find('input[type="radio"]'),
          $label = $this.find('label'),
          $select = $('<select>'),
          selectOptions = '';

      if (!$input.length) {
        return;
      }

      // If given a groupHeading element, use it to create a placeholder-esque
      // option for the current <select>
      if (groupHeading.length) {
        selectOptions = '<option class="select-placeholder" disabled selected>' + groupHeading.text().trim().replace(/\:$/, '') + '</option>';
      }

      // Continue building out the select options using all the radio/checkbox inputs.
      $input.each(function buildSelectOptions() {
        var $this = $(this),
            $label = $this.next('label'),
            triggerElement = '#' + $this.attr('id').trim(),
            isSelected = ($this.is(':checked')) ? 'selected' : '';

        // Let the option value be the input element to trigger, by DOM id.
        selectOptions += '<option value="' + triggerElement + '" ' + isSelected + '>' + $label.text() + '</option>';

        // Sync the select state when the option input is used.
        $this.on('change.dynamicfilter', function twoWayValueBind() {
          $select.find('option[value="' + triggerElement + '"]').prop('selected', true);
        });
      });

      // Flesh out the select, and enact bindings.
      $select.html(selectOptions)
        .on('change.dynamicfilter', function bindDynamicSelectActions() {
          var $triggerEl = $($(this).val());

          $triggerEl.prop('checked', true).trigger('change');
        })
        .appendTo($selectContainer);

        // Apply any per instance callbacks.
        if (typeof _options.onCreateSelectCallback === 'function') {
          _options.onCreateSelectCallback.call($select);
        }
    });
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      if (!$.data(this, 'plugin_' + pluginName)) {
        $.data(this, 'plugin_' + pluginName,
        new Plugin(this, options));
      }
    });
  };
})(jQuery);

"use strict";

/**
 * jQuery Float Labels
 *
 * A simple plugin to enable the floating label pattern. It makes no attempt to
 * control any interactions of the label within js. It just binds to events as
 * needed and triggers configurable CSS classes.
 *
 * The jQuery method is called on the wrapper element that contains both the field
 * and the label. It might look like:
 *
 * <div class="field__wrapper">
 *   <label class="field__label" for="this-field">My Super Label</label>
 *   <input name="this-field">
 * </div>
 *
 * The javascript init, with options thrown in:
 * $('.field__wrapper').floatLabel({
 *   activeClass: 'activated',
 *   focusClass: 'zenified'
 * });
 */
(function ($) {
  // Set plugin method name and defaults
  var pluginName = 'floatLabel',
      defaults = {
        // In case you want to preserve labels as visible for no js, or old
        // IE users.
        wrapperInitClass: 'has-float-label',
        // For a custom label selector, if you have multiple labels, for some
        // reason.
        labelSelector: false,
        // Class given to label when its field has a non-null value. Toggled
        // when the value is empty / falsy.
        activeClass: 'is-active',
        // Class given to input when it has an empty value.
        emptyClass: 'is-empty',
        // Class given to label when its field is focused. Toggled when it
        // loses focus.
        focusClass: 'has-focus',
        // Class for lack of proper placeholder support.
        badSupportClass: 'is-msie'
      },
      // Detect misbehaved user agents.
      hasBadPlaceholderSupport = Boolean(window.navigator.userAgent.match(/(MSIE |Trident\/)/));

  // plugin constructor
  function Plugin (element, options) {
    var $element = $(element);

    // Set up internals for tracking, just in case.
    this._name = pluginName;
    this._defaults = defaults;
    this._element = $element;

    // Use the init options.
    this.options = $.extend(true, defaults, options);

    // Set up a couple of internals to keep track of input and label.
    this._wrapper = $element;
    this._input = this._findInput($element);
    this._label = this._findLabel($element);

    // Do it now.
    this.init();
  }

  // Utility: find a input that we want to alter the label for.
  Plugin.prototype._findInput = function($el) {
    var $textInputs = $el.find('input, textarea').not('[type="checkbox"], [type="radio"]');
    // The regular text input types.
    if ($textInputs.length) {
      return $textInputs;
    }
    // Try for select elements.
    else {
      return $el.find('select');
    }
  };

  // Utility: find a label in the field wrapper element.
  Plugin.prototype._findLabel = function(el) {
    // If a custom selector is provided
    if (this.options.labelSelector) {
      return $(el).find(this.options.labelSelector);
    }

    // Just try a label element.
    return $(el).find('label');
  };

  Plugin.prototype._checkValue = function () {
    var isEmpty = this._input.val() === '' || this._input.val() === '_none';

    // Apply the correct classes based on value emptiness.
    this._input.toggleClass(this.options.emptyClass, isEmpty);
    this._label.toggleClass(this.options.activeClass, !isEmpty);

    // Apply the bad placeholder support classes if needed.
    this._label.add(this._input)
      .toggleClass(this.options.badSupportClass, hasBadPlaceholderSupport);
  };

  Plugin.prototype._onKeyUp = function () {
    this._checkValue();
  };

  Plugin.prototype._onFocus = function () {
    this._label.addClass(this.options.focusClass);
    this._onKeyUp();
  };

  Plugin.prototype._onBlur = function () {
    this._label.removeClass(this.options.focusClass);
    this._onKeyUp();
  };

  Plugin.prototype.init = function () {
    // Mark the element as having been init'ed.
    this._element.addClass(this.options.wrapperInitClass);

    // Check value for initial active class.
    this._checkValue();

    // Event bindings to the input element with floatLabels namespace.
    this._input
      .off('keyup.floatLabels change.floatLabels')
      .on('keyup.floatLabels change.floatLabels', $.proxy(this._onKeyUp, this));
    this._input
      .off('blur.floatLabels')
      .on('blur.floatLabels', $.proxy(this._onBlur, this));
    this._input
      .off('focus.floatLabels')
      .on('focus.floatLabels', $.proxy(this._onFocus, this));
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, new Plugin(this, options));
    });
  };
})(jQuery);

/**
 * Tabs content utility
 *
 * Create interactive tabs that switch between different visible content when
 * tabs are clicked.
 *
 * Options:
 *   tabLinks - Required - [jQuery Object] - element(s) to be used as a trigger
 *   contents - Required - [jQuery Object] - element(s) to use as content wrapper
 *   triggers - Optional - [jQuery Object] - additional elements (other than tabs)
 *     used for triggering the display of specific tabs
 *
 * Usage:
 *   $('.tabs-wrapper-selector').tabs({
 *     tabLinks: $('.tab-links-selector'),
 *     contents: $('.tab-contents-wrapper-selector'),
 *     triggers: $('.tab-triggers-selector')
 *   });
 */

(function ($) {
  const pluginName = 'tabs';

  // plugin constructor
  function Plugin (element, options) {
    this.element = $(element);

    // Use the init options.
    this.options = $.extend({}, options);

    // Limit tabLinks and contents down to only the set within this instance.
    this.options.tabLinks = this.element.find(this.options.tabLinks).add(this.element.find(this.options.triggers));
    this.options.contents = this.element.find(this.options.contents);

    // Do setup stuff.
    this.init();

    // Display tab automatically if there's a matching hash in the URL.
    if (window.location.hash.length) {
      this.autoReveal();
    }
  }

  /**
   * Brings a tab into view based on the corresponding tab link passed.
   *
   * @param {jQuery} $link - The link corresponding to the tab to display
   * @param {Object} settings - Additional options to override defaults including:
   * @param {string} settings.scrollBehavior - how scrolling should be handled.
   */
  Plugin.prototype.showTab = function($link, settings) {
    const $content = $('#' + $link.data('tab-content'));
    const $previousLink = this.options.tabLinks.filter('.is-active');
    const $flyoutContainer = $content.closest('.flyout__content');
    const href = $link.attr('href');
    const scrollOffset = $('.sticky-wrapper .stuck').outerHeight(true);
    const defaultSettings = {
      scrollBehavior: this.element.data('tabs-scroll')
    };

    // Merge settings with defaults.
    settings = $.extend({}, defaultSettings, settings);

    if (!$link.hasClass('is-active')) {
      // Manage active class
      this.options.tabLinks.add(this.element.find(this.options.contents)).removeClass('is-active');
      $link.add($content).addClass('is-active');
      // Update accessibility roles and attributes.
      if ($previousLink.length) {
        $previousLink.removeAttr('aria-selected');
      }
      $link.attr('aria-selected', 'true');

      // Manage flyout container if tabs are within a flyout.
      if ($flyoutContainer.length) {
        const $parent = $flyoutContainer.offsetParent();
        const startHeight = $parent.outerHeight(true);
        const endHeight = $content.closest('.tabs__wrapper').offsetParent().outerHeight(true);
        Components.utils.transitionHeight($parent[0], { startHeight, endHeight });
      }
    }

    // Handling scrolling behaviors
    if (settings.scrollBehavior) {
      let $scrollTarget;

      switch (settings.scrollBehavior) {
        case 'wrapper':
          $scrollTarget = this.element;
          break;
        case 'content':
          $scrollTarget = $content;
          break;
        default:
          $scrollTarget = $('#' + settings.scrollBehavior);
          break;
      }

      Components.utils.smoothScrollTop($scrollTarget, scrollOffset, false);
    }

    // Push the current state to the URL
    if ((href.indexOf('#')) === 0 && (href.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, href);
    }

    // Trigger an event to listen to from other scripts.
    this.element.trigger(pluginName + '.showTab');
  };

  // Automatically reveal content when the ID of the container is in the URL
  // hash.
  Plugin.prototype.autoReveal = function () {
    const hash = window.location.hash;
    const $tabLink = this.options.tabLinks.filter('[href="' + hash + '"]');

    // Make sure the tab link exists and only run if it's within the current
    // tabs wrapper.
    if ($tabLink.length && this.element.find($tabLink).length) {
      let $flyoutContainer;
      let $flyoutTrigger;
      $flyoutContainer = $tabLink.closest('.flyout__content');
      let scroll = $flyoutContainer.length ? false : 'wrapper';

      // Show the correct tab with quick animation.
      this.showTab($tabLink, {
        scrollBehavior : scroll,
      });

      // If the tab is inside a content flyout, make sure that the
      // content flyout is opened.
      if ($flyoutContainer.length && !$flyoutContainer.hasClass('is-open')) {
        $flyoutTrigger = $flyoutContainer.data('flyout-trigger');
        $flyoutContainer[0].contentFlyout.showContent($flyoutTrigger, {
          scroll: true,
          scrollDown: true
        });
      }
    }
  };

  // Hand-full of setup tasks
  Plugin.prototype.init = function () {
    const _this = this;
    const $flyoutContainer = _this.element.find('.flyout__content');
    const $tabList = _this.element.find('.tabs__tab-list, .tabs__tabs');

    // Set up properties for wrapper
    $tabList.attr('role', 'tablist');

    if (_this.options.tabLinks.length && _this.options.contents.length) {
      // Show tabs on click.
      _this.options.tabLinks.on('click.tabs', function(e) {
        e.preventDefault();
        _this.showTab($(this));
      });

      // Set up tab link properties.
      _this.options.tabLinks.each(function(index, el) {
        const tabId = $(el).data('tab-content');
        const fragment = '#' + tabId;

        // Add accessible properties
        $(el).attr('role', 'tab');
        $(el).parent().attr('role', 'presentation');
        // Set an  id for the panel to use as aria-labelledby
        $(el).attr('id', 'tab-link__' + tabId);

        // If we're within a flyout, prefix with the flyout's ID
        if ($flyoutContainer.length) {
          fragment = '#' + $flyoutContainer.attr('id') + '-' + tabId;
        }

        // Set the href for the tab as well as any triggers that target the
        // same content.
        if ($(el).attr('href').indexOf('#') === 0) {
          $(el).attr('href', fragment);
        }
      });

      // Set up panel properties.
      _this.options.contents.each(function (index, el) {
        const id = $(el).attr('id');
        // Add accessible properties
        $(el).attr('role', 'tabpanel');
        $(el).attr('aria-labelledby', 'tab-link__' + id);
      })

      // Handle other triggers displaying
      if (_this.options.triggers) {
        _this.options.triggers.on('click.tabs-trigger', function (e) {
          const $link = _this.options.tabLinks.filter('[data-tab-content="' +
            $(this).data('tab-content') + '"]');
          const $content = $('#' + $(this).data('tab-content'));

          if ($link.length) {
            // Manage active class
            _this.options.tabLinks.add(_this.options.contents).removeClass('is-active');
            $link.add($content).addClass('is-active');
          }

          // Push the current state to the URL
          if (history.replaceState) {
            history.replaceState(undefined, undefined, $link.attr('href'));
          }

          e.preventDefault();
        });
      }
    }
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[pluginName] = function (options) {
    return this.each(function initPlugin() {
      const plugin = new Plugin(this, options);
      // Allow the plugin to be instantiated more than once. Event handlers
      // will be re-bound to avoid issues.
      $.data(this, 'plugin_' + pluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   Ex. element.tabs.showTab();
      this.tabs = plugin;
    });
  };
})(jQuery);

/**
 * Fancy Filters interactions
 */
(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {
    $('.fancy-filters')
    // Clear any previously bound handlers.
    .off('click.fancy-filters')
    // Handle "Clear Filters" click by unchecking everything.
    .on('click.fancy-filters', '.fancy-filters__clear', function (e) {
      $(e.delegateTarget).find('input:checked').prop('checked', false).change();
      return false;
    });
  });
})(jQuery);

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

Components.form = {};

// Closure to rename jQuery to $.
(function ($) {

  Components.form.initFloatLabels = function ($elements) {
    $elements.find('input, select, textarea')
      .not('[type="checkbox"], [type="radio"]')
      .closest('.form-field')
      .floatLabel({
        labelSelector: '.form-field__label'
      });
  };

  $(document).ready(function () {
    Components.form.initFloatLabels($('.has-float-label'));
  });

  $(document).on('initFloatLabels', function (e) {
    Components.form.initFloatLabels($(e.target));
  });

})(jQuery);

/**
 * Flyout Form component interaction
 * See jquery.contentFlyout.js for details
 */

(function ($) {
  $(document).ready(function() {
    var fragment = 'form',
        $formWrapper = $('.flyout-form'),
        $triggers = $('a[href*="#' + fragment + '"], .flyout-form__trigger'),
        $closeLink = $formWrapper.find('.flyout-form__close'),
        $pageWrapper = $('main');

    // Make sure a flyout form exists before proceding.
    if ($formWrapper.length && $triggers.length) {
      // Append form wrapper to the page wrapper.
      $pageWrapper.append($formWrapper);

      // If a close button doesn't already exist, add it.
      if (!$closeLink.length) {
        $closeLinkWrapper = $('<div class="flyout-form__close-wrapper">');
        $closeLink = $('<button class="flyout-form__close link link--close">Close</button>');
        $closeLinkWrapper.prepend($closeLink);
        $formWrapper.prepend($closeLinkWrapper);
      }

      // Set the reveal target on the triggers (used by contentFlyout plugin).
      $triggers.data('flyoutTarget', fragment);

      // Flyout Magic using contentFlyout plugin
      $formWrapper.contentFlyout({
        triggers: $triggers,
        slideout: $pageWrapper,
        closeLinks: $closeLink,
        scroll: false
      });

      // When open, clicking the page wrapper should close the flyout.
      $pageWrapper.on('click.flyout', function(e) {
        if ($(this).hasClass('is-open')) {
          $formWrapper[0].contentFlyout.hideContent();
          e.preventDefault();
        }
      });

      // Stop propagation of click events on the flyout form itself.
      $formWrapper.on('click.flyout', function (e) {
        e.stopPropagation();
      });

      // Show form on load if the ULR contains the fragment.
      if (window.location.hash === '#' + fragment) {
        // Make sure to only "click" the first trigger."
        $formWrapper[0].contentFlyout.showContent();
      }

      // Close form on hitting Escape key
      $(document).keyup(function(e) {
        if (e.keyCode == 27 && $formWrapper.hasClass('is-open')) { // escape key maps to keycode `27`
          $formWrapper[0].contentFlyout.hideContent();
        }
      });

      // Auto-focus on the first field of the form when it's revealed.
      $triggers.on('click.flyout', function(e) {
        // Make sure we're actually listening to a click on the trigger link
        // rather than a JS event trigger.
        if ($(e.currentTarget).is($triggers)) {
          $formWrapper.find('input:visible').first().focus();
        }
      });
    }
  });
}(jQuery));

/**
 * Compact form.
 *
 */
(function ($) {
  $(document).ready(function () {
    var fragment = 'form',
        $form = $('.form-compact'),
        $cta = $form.find('button.form__button.cta'),
        $triggers = $('a[href*="#' + fragment + '"]');

    // Make sure a compact form exists before proceeding.
    if ($form.length && $cta.length) {
      // Show form on load if the URL contains the form fragment.
      if (window.location.hash === '#' + fragment) {
        if (isReveal()) {
          revealForm();
        }
        $form.find('input:visible').first().focus();
      }

      $cta.click(function (e) {
        if (isReveal() && !$form.hasClass('is-open')) {
          revealForm();
          return false;
        }

        // Support hidden form submits.
        $(this.form).find('input[type="submit"]').click();

        return false;
      });

      // Auto-focus on the form field when it's revealed.
      $triggers.on('click', function(e) {
        revealForm();
      });

      /**
       * Returns whether this is a reveal.
       * @returns Boolean
       */
      function isReveal() {
        return $form.hasClass('form-compact--reveal');
      }

      /**
       * Reveal the e-mail only form.
       */
      function revealForm() {
        var ctaText = $cta.val() || $cta.text();

        if (!$form.hasClass('is-open')) {
          $form.toggleClass('is-open');
          $cta.text(ctaText);
        }

        $form.find('input:visible').first().focus();
      }
    }
  });
})(jQuery);

/**
 * Responsive filters interaction
 *
 * See jquery.dynamicSelectFilters.js
 */
(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {
    $('.responsive-filter').dynamicSelectFilters({
      container: '.responsive-filter__select',
      groupHeading: '.responsive-filter__heading',
      onCreateSelectCallback: function () {
        // 'this' is the jQuery wrapped select element, created per group set.
        this.wrap('<div class="form__select"></div>');
      }
    });
  });
})(jQuery);

/**
 * Search Facet behaviors.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.searchFacet = {
  collapsedClass: 'coveo-facet-collapsed'
};

// Closure to extend behavior, provide privacy and state.
(function (component, $) {

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function ($) {
    // Close open search facets when clicking outside of one, or when ESC key is pressed.
    $(document).on('click.searchFacet', function (e) {
      component.closeAll();
    })
    // Also, close on Escape key.
    .on('keydown', function (e) {
      if (e.keyCode === 27) {
        component.closeAll();
      }
    });

    // Initialize search facet components.
    $('.search-facet').on('click.searchFacet', '.search-facet__header, .coveo-facet-header', function (e) {
      var element = e.delegateTarget,
          isCollapsed = $(element).hasClass(component.collapsedClass);

      // Close any other open facets.
      component.closeAll({$except: $(element)});

      // Using Coveo JS Framework.
      // Related: https://coveo.github.io/search-ui/components/facet.html#expand
      if (element.CoveoFacet) {
        if (isCollapsed) {
          element.CoveoFacet.expand();
        }
        else {
          element.CoveoFacet.collapse();
        }
      }
      // Otherwise, just use the class.
      else {
        $(element).toggleClass(component.collapsedClass)
      }

    });

    // Stop propagation of clicks upward to the document to prevent closing.
    $('.search-facet').on('click.searchFacet', function (e) {
      e.stopPropagation();
    });
  };

  /**
   * Closes all open CoveoFacet components.
   *
   * @param {Object} options
   *   options.$except  Exclude a given jQuery selection — uses .not()
   */
  component.closeAll = function (options) {
    var $closeFacets = $('.search-facet');

    if (options && options.$except) {
      $closeFacets = $closeFacets.not(options.$except);
    }

    $closeFacets.each(function () {
      // Using Coveo JS Framework.
      // Related: https://coveo.github.io/search-ui/components/facet.html#collapse
      if (this.CoveoFacet) {
        this.CoveoFacet.collapse();
      }
      // Otherwise, just use the class.
      else {
        $(this).addClass(component.collapsedClass);
      }
    });
  };

})(Components.searchFacet, jQuery);

// Attach our DOM-ready callback.
jQuery(Components.searchFacet.ready);

/**
 * Accordion component interaction
 * See jquery.accordion.js for details
 */

 (function($){
   $(document).ready(function(){
     $('.accordion').each(function () {
       $(this).accordion({
         itemSelector: '.accordion__item',
         headerSelector: '.accordion__title-wrapper',
         contentSelector: '.accordion__content-wrapper'
       });
     });
   });
 })(jQuery);

/**
 * Components.AccordionGrid is a jQuery friendly plugin with an exposed JS API.
 * `component` is an alias for Components.AccordionGrid object, which doubles as
 * the constructor function.
 *
 * State classes:
 *   .is-expanded - An accordion item when it's expanded.
 *
 * On DOM-ready, all elements with the `accordion-grid` class will automatically
 * be instantiated.
 *
 * Initialize yourself using jQuery `.tabAccordionGrid()`:
 *   $('.my-element').tabAccordionGrid();
 *
 * API Examples:
 *   Components.AccordionGrid.closeItems()
 *
 *   var myAccordionGrid = $('.element')[0].AccordionGrid;
 *   myAccordionGrid.openItem();
 *   myAccordionGrid.closeItem();
 *
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a constructor and base object for this component's data and functions.
Components.AccordionGrid = function (element, options) {
  // Set up internal properties.
  this.defaultOptions = {
    itemSelector: '.accordion-grid__item',
    teaserSelector: '.accordion-grid__teaser',
    detailSelector: '.accordion-grid__detail',
    detailWrapperSelector: 'accordion-grid__detail-wrapper',
    closeClass: 'accordion-grid__close',
    animation: {
      duration: 750,
      easing: 'easeInOutQuart'
    }
  };
  this.$element = $(element);

  // Use the init options, with defaults.
  this.options = {
    ...this.defaultOptions,
    ...options,
  };

  // Initialize this instance.
  this.init();
};

// Closure to encapsulate and provide the component object and jQuery in the local scope.
(function (AccordionGrid, $) {

  /**
   * Component state and variables.
   */
  AccordionGrid.jQueryPluginName = 'tabAccordionGrid';
  AccordionGrid.instances = [];

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  AccordionGrid.ready = function () {
    // Initialize every instance on the page.
    $('.accordion-grid').tabAccordionGrid();
  };

  /**
   * Initialize the component (jQuery plugin).
   */
  AccordionGrid.prototype.init = function () {
    const _this = this;
    const $accordionGrid = this.$element;
    const $details = $accordionGrid.find(this.options.detailSelector);
    const $closeLink = $('<a href="#" class="' + this.options.closeClass + '"><i class="icon icon--close-window-style2"></a>');
    let lastToggleTime = 0;

    // Adding a close link to each detail container.
    // Exclude detail containers which already contain a close link.
    $details.not($details.has('.' + this.options.closeClass)).prepend($closeLink);

    // Add a wrapper element around detail container for CSS based open/close animation.
    $details.each(function() {
      const $this = $(this);
      if (!$this.parent(_this.options.detailWrapperSelector).length) {
        $this.wrap(`<div class="${_this.options.detailWrapperSelector}"></div>`);
      }
    });

    // Handle clicking on the item's teaser and use debounced function.
    $accordionGrid
      .off('click.accordion-grid-teaser')
      .on('click.accordion-grid-teaser', this.options.teaserSelector, function () {
        // Toggle the clicked item, but skip if we're still animating another item.
        const now = new Date().valueOf();
        if (now - lastToggleTime > _this.options.animation.duration) {
          // Expand or collapse the clicked item's details.
          _this.toggleItem($(this).closest(_this.options.itemSelector));
          lastToggleTime = now;
        }
      });

    // Handle closing an item when the close link is clicked.
    $accordionGrid
    .off('click.accordion-grid-close')
    .on('click.accordion-grid-close', '.' + _this.options.closeClass, function (e) {
      var $item = $(this).closest(_this.options.itemSelector);

      _this.closeItem($item);
      e.preventDefault();
    });

    // Auto open an item if a corresponding hash is in the URL
    this.hashOpen();

    // Append to our instances array.
    AccordionGrid.instances.push($accordionGrid);
  };

  /**
   * Toggle a specified item open or closed in a specific instance.
   * @param {jQuery object} $item The item to be toggled
   */
  AccordionGrid.prototype.toggleItem = function ($item) {
    if ($item.hasClass('is-expanded')) {
      this.closeItem($item);
    }
    else {
      this.openItem($item);
    }
  };

  /**
   * Open a specified item in a specific instance.
   * @param {jQuery object} $item - The item to be opened
   */
  AccordionGrid.prototype.openItem = function ($item) {
    const id = $item.attr('id');

    // Collapse any expanded items
    this.closeItems();

    // Expand the specified item's details
    $item.add($item.find(this.options.detailSelector)).addClass('is-expanded');

    // Push the current state to the URL
    if ((id.length > 1) && (history.replaceState)) {
      history.replaceState(undefined, undefined, '#' + id);
    }
  };

  /**
   * Close a specified item in a specific instance.
   * @param {jQuery object} $item The item to be closed
   */
  AccordionGrid.prototype.closeItem = function ($item) {
    const hash = window.location.hash;

    // Collapse the specified item's details
    $item.add($item.find(this.options.detailSelector)).removeClass('is-expanded');

    // Clear the hash of a given item if it's set in the URL
    if (hash.length > 1 && $item.is(hash) && history.replaceState) {
      history.replaceState(undefined, undefined, window.location.pathname);
    }
  };

  /**
   * Close all items in a specific instance
   * @return {jQuery object} collection of all items that were closed.
   */
  AccordionGrid.prototype.closeItems = function () {
    const _this = this;
    const $accordionGrid = this.$element;
    const $openItems = $accordionGrid.find(this.options.itemSelector).filter('.is-expanded');

    // Collapse all item details and remove state class
    $openItems.each(function () {
      _this.closeItem($(this));
    });

    // Return items that were closed.
    return $openItems;
  };

  /**
   * Check for a hash in the URL and open any corresponding accordion item.
   */
  AccordionGrid.prototype.hashOpen = function () {
    const hash = window.location.hash;

    // If the hash exists (e.g. #something) and it matches using jQuery selection.
    if (hash.length > 1 && this.$element.find(hash).length) {
      this.openItem($(hash), {
        animation: {duration: 0}
      });
    }
  };

  // Lightweight constructor.
  $.fn[AccordionGrid.jQueryPluginName] = function (options) {
    return this.each(function initPlugin() {
      const plugin = new AccordionGrid(this, options);
      $.data(this, 'plugin_' + AccordionGrid.jQueryPluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   e.g., element.AccordionGrid.close();
      this.AccordionGrid = plugin;

      // Trigger custom initialized event on the element.
      $(this).trigger('initialized');
    });
  };

  // DOM-ready handler.
  $(AccordionGrid.ready);

  // AJAX handling magic.
  $(document).on('components:reattach', AccordionGrid.ready);

}(Components.AccordionGrid, jQuery));

/**
 * Countdown Clock component interaction
 * This script takes the client's current time and subtracts it from a given countdown deadline.
 * It modifies the days, hours, and minutes on the countdown timer component.
 */

(function ($) {
  let convertEventTime;

  $(document).ready(function () {
    initCountdown();
  });


  function initCountdown() {
    const $clock = $('.countdown-clock');

    // Exit if no countdowns exist on the page.
    if (!$clock.length) {
      return;
    }

    const clockDeadline = $clock.attr('data-clock-deadline');
    const now = new Date().getTime();
    // Grab the countdown-clock element and convert the provided ISO 8601
    // datetime string into a js date object. We'll use this as a UTC datetime
    // with no offset and caculate the difference in the update function.
    // For an easy time converter: https://savvytime.com/converter/pst-to-utc.
    convertEventTime = new Date(clockDeadline).getTime();

    if (convertEventTime > now) {
      updateCountdown();
    } else {
      setTimeZero();
    }
  }

  function updateCountdown() {
    // Calculate the time difference between the deadline time and the current
    // time. This will be in milliseconds.
    const currentTime = new Date().getTime();
    const timeDiff = convertEventTime - currentTime;

    if (timeDiff < 0) {
      setTimeZero();
      return;
    }

    // Calculate out the days.
    let msecDiff = timeDiff;
    let days = Math.floor(msecDiff / (1000 * 60 * 60 * 24));

    // Subtract out the days and calculate the hours.
    msecDiff -= days * 1000 * 60 * 60 * 24;
    let hours = Math.floor(msecDiff / (1000 * 60 * 60));

    // Subtract out the hours and calculate the minutes.
    msecDiff -= hours * 1000 * 60 * 60;
    let minutes = Math.floor(msecDiff / (1000 * 60));

    // Subtract out the minutes and calculate the seconds.
    msecDiff -= minutes * 1000 * 60;
    let seconds = Math.floor(msecDiff / 1000);

    // Show how much time is left per calculated unit. Grab each of the
    // elements for days/hours/minutes so we can modify them.
    $('.countdown-clock__days').text(days);
    $('.countdown-clock__hours').text(hours);
    $('.countdown-clock__minutes').text(minutes);
    $('.countdown-clock__seconds').text(seconds);

     // Update the counter approximately every 1 second.
    setTimeout(updateCountdown, 1000);
  }

  function setTimeZero() {
    $('.countdown-clock__days').text(0);
    $('.countdown-clock__hours').text(0);
    $('.countdown-clock__minutes').text(0);
    $('.countdown-clock__seconds').text(0);
  }

}(jQuery));

/**
 * Currency switcher interactions
 */
(function ($, cookies) {
  $(document).ready(function () {
    // Get variables for currency switcher component.
    const $currencySwitcher = $('.currency-switcher');
    const $currencySwitcherOptions = $('.currency-switcher__options');

    // Upon clicking the currency switcher options, we're going to set the corresponding active currency.
    if ($currencySwitcher.length) {
      $currencySwitcherOptions.on('change', function () {
        // Get variables for currency data.
        let $dataCurrency = this.value;

        // When the currency option is selected, set active class for price tag.
        $('.price-tag__price span').removeClass('is-active');
        $('.price-tag__price span[data-currency="' + $dataCurrency + '"]').addClass('is-active');

        // Set the cookie.
        cookies.set('tableauCurrency', $dataCurrency, {
          expires: 365,
          domain: '.tableau.com'
        });
      });

      // Set active currency based on cookie.
      if (cookies.get('tableauCurrency')) {
        // Set selected attribute on currency switcher option.
        $('option[value="' + cookies.get('tableauCurrency') + '"]').prop('selected', true);

        // Set correct currency.
        $('.price-tag__price span').removeClass('is-active');
        $('.price-tag__price span[data-currency="' + cookies.get('tableauCurrency') + '"]').addClass('is-active');
      }
    }
  });
})(jQuery, Cookies);

/**
 * Flyout content component interaction
 * See jquery.contentFlyout.js for details
 */

(function ( $ ) {
  $(document).ready(function(){
    $('.flyout__content').contentFlyout({
      triggers: $('.flyout__trigger'),
      slideout: $('.flyout__slideout'),
      closeLinks: $('.flyout__close-link')
    });
  });
}( jQuery ));

/**
 * Modal message behaviors.
 *
 * - Toggle .is-open state on component, e.g when showing modal message.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.modalMessage = {};

// Closure to rename Components.modalMessage
(function (component, $) {

  /**
   * Variables
   */
  component.modifiers = {
    'loading': 'modal-message--loading'
  };

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function () {
    $('.modal-message, .modal-message__close').click(function (e) {
      if (e.target === this) {
        $('.modal-message').removeClass('is-open');
        e.preventDefault();
      }
    });
  };

  /**
   * Show modal message
   *
   * @param {string} message
   *   Optional message you want to display.
   * @param {string} type
   *   Optional parameter to alter the style of the message box.
   *   Example:
   *   'loading' - Show a loading modal message.
   */
  component.show = function (message, type) {
    var $modalMessage = $('.modal-message');

    // Initialize our modal message;
    if (!$modalMessage.length) {
      $modalMessage = $('<div class="modal-message">' +
        '<div class="modal-message__dialog">' +
        '<div class="modal-message__icon"></div>' +
        '<div class="modal-message__content"></div>' +
        '<a href="#" class="modal-message__close"></a>' +
        '</div>' +
        '</div>');
      $('body').append($modalMessage);
    }

    for (var key in component.modifiers) {
      if (type === key) {
        $modalMessage.addClass(component.modifiers[key]);
      }
      else {
        $modalMessage.removeClass(component.modifiers[key]);
      }
    }

    // Replace the content of our message.
    if (message) {
      component.update(message);
    }

    // Show our modal message.
    if (!$modalMessage.hasClass('is-open')) {
      $modalMessage.addClass('is-open');
    }
  };

  /**
   * Update message.
   *
   * @param {string} message
   *   Message you want to display.
   */
  component.update = function (message) {
    $('.modal-message__content').html(message);
  };

  /**
   * Close modal message
   */
  component.close = function () {
    $('.modal-message').removeClass('is-open');
  };

  // Dom ready handler.
  $(component.ready);

}(Components.modalMessage, jQuery));

(function($) {
  $(document).ready(function() {

    /**
     * Handles closing the notification.
     *
     * Uses transition height helper.
     * @see _transition-height.scss.
     */
    const $globalNotificationWrapper = $('.global-notification__wrapper');
    $('.global-notification').addClass('transition-height');
    $globalNotificationWrapper.addClass('transition-height__inner is-open');

    $('.global-notification .global-notification__close').click(function (e) {
      e.preventDefault();

      $globalNotificationWrapper.removeClass('is-open');
    });
  });
})(jQuery);

(function($) {
  $.fn.moveProgressBar = function (progress) {
    var $el = $(this),
        $progress = $el.find('.progress'),
        progress = progress || parseInt($progress.data('progress')) || 0,
        treshold = [5, 50, 100],
        modifier = '';

    for (var i in treshold) {
      if (progress <= treshold[i]) {
        modifier = 'progress--' + treshold[i];
        break;
      }
    }

    // Make sure we have a valid percentage.
    progress = (progress > 100) ? 100 : progress;

    $progress.removeClass (function (index, css) {
      return (css.match (/(^|\s)progress--\S+/g) || []).join(' ');
    }).css({
      'width': progress + '%'
    }).addClass(modifier);
  };
}( jQuery ));

/**
 * Reveal content component interaction
 * See jquery.contentReveal.js for details
 */

jQuery(document).ready(($) => {
  $('.reveal__content').contentReveal();
});

/**
 * Social Share interaction.
 *
 * Ensures social share can be accessible by keyboard.
 */
$(document).ready(function () {
  const $socialShareContainer = $('.social-share');
  // Programmatically add the aria attributes for backwards compatibility.
  // This is for component use already existing in content.
  $socialShareContainer.each(function () {
    const $container = $(this);
    const $toggle = $container.find('.social-share__toggle');

    // Replace anchor links with a button.
    if ($toggle.is('a')) {
      const shareIconClasses = $toggle.find('i').attr('class');
      const labelText = $toggle.text().trim();

      const $button = $('<button>', {
        class: 'social-share__toggle',
        'aria-expanded': 'false',
        'aria-controls': 'social-share-widgets',
        html: `<i class="${shareIconClasses}" aria-hidden="true"></i>${labelText ? ' ' + labelText : ''}`
      });
      $toggle.replaceWith($button);
    }

    const $widgets = $container.find('.social-share__widgets');
    if (!$widgets.attr('id')) {
      $widgets.attr('id', 'social-share-widgets').attr('aria-hidden', 'true');
    }

    // Ensure the widgets are wrapped in an unordered list for proper structure.
    if ($widgets.children('ul.social-share__list').length === 0) {
      const $list = $('<ul>', { class: 'social-share__list' });

      $widgets.children().each(function () {
        const $widget = $(this);
        const widgetType = $widget.data('social');

        if (!$widget.closest('li.social-share__item').length) {
          const $item = $('<li>', { class: `social-share__item social-share__item--${widgetType}` });

          $widget.addClass(`social-share__widget social-share__widget-${widgetType}`).appendTo($item);

          // Add aria-label to the <a> element dynamically so screen
          // doesn't just read out "link", which is unhelpful for a user.
          const $link = $widget.find('a.social-share__link');
          if ($link.length) {
            $link.attr('aria-label', `${widgetType.charAt(0).toUpperCase() + widgetType.slice(1)} link`);
          }
          $list.append($item);
        }
      });

      // Replace the contents of the widget container with the list.
      $widgets.html($list);
    }

    // Add a screen-reader-only status div if not already present.
    // Otherwise, existing pages with the component in content will not work!
    if ($container.find('.sr-only[aria-live="assertive"][role="status"]').length === 0) {
      const $statusDiv = $('<div>', {
        class: 'sr-only',
        'aria-live': 'assertive',
        role: 'status'
      });
      $container.append($statusDiv);
    }
  });

  const $toggleButton = $('.social-share__toggle');
  const $statusMessage = $('.sr-only[role="status"]');

  if (!$statusMessage.length) {
    return;
  }
  const toggleDropdown = ($button, isOpen) => {
    const $parent = $button.closest('.social-share');
    const $widgetsDropdown = $parent.find('.social-share__widgets');
    $parent.find('.social-share__toggle').attr('aria-expanded', isOpen);
    $widgetsDropdown.attr('aria-hidden', !isOpen);
    const message = isOpen ? 'Social share dropdown expanded.' : 'Social share dropdown collapsed.';
    $statusMessage.text(message);
  };

  // Handle keyboard navigation.
  $toggleButton.on('keydown', function (e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      const $this = $(this);
      const isExpanded = $this.attr('aria-expanded') === 'true';
      toggleDropdown($this, !isExpanded);
    }
  });

  // Close the dropdown on Escape.
  $(document).on('keydown', function (e) {
    if (e.key === 'Escape') {
      $toggleButton.each(function () {
        const $this = $(this);
        if ($this.attr('aria-expanded') === 'true') {
          toggleDropdown($this, false);
        }
      });
    }
  });

  // Hover to reveal the dropdown menu.
  $socialShareContainer.hover(
    function () {
      const $this = $(this);
      toggleDropdown($this,true);
    },
    function () {
      const $this = $(this);
      toggleDropdown($this,false);
    }
  );

  // Close dropdown when focus leaves the container.
  $socialShareContainer.on('focusout', function (e) {
    const $this = $(this);
    if (!$(e.relatedTarget).closest('.social-share').length) {
      toggleDropdown($this,false);
    }
  });
});

/**
 * Tooltip component interaction.
 *
 * Ensure tooltip can be closed with escape key when focused.
 */

(function ($) {
  $(document).on('ready components:reattach', function() {
    const $tooltip = $('.tooltip');
    const $tooltipText = $('.tooltip__text');

    $tooltip.off('keydown.tooltip');
    $tooltip.off('focus.tooltip');

    $tooltip.on({
      'keydown.tooltip': (e) => {
        if (e.key === 'Escape') {
          $tooltipText.removeClass('activated');
          $tooltip.removeClass('activated');
          $tooltip.blur();
        }
      },
      'focus.tooltip': () => {
        $tooltipText.addClass('activated');
        $tooltip.addClass('activated');
      }
    })
  });
}( jQuery ));

/**
 * Tabs component interaction
 * See jquery.tabs.js for details
 */

(function ($) {
  $(document).ready(function () {
    $('.tabs__wrapper').each(function () {
      var $this = $(this),
          $triggers = $this.find('.tabs__tab-trigger'),
          $flyoutTriggers = $this.closest('.flyout__content').siblings('.flyout__slideout').find('.tabs__tab-trigger');

      $this.tabs({
        tabLinks: $this.find('.tabs__tab-link'),
        contents: $this.find('.tabs__tab-content'),
        triggers: $triggers.add($flyoutTriggers)
      });
    });
  });
}(jQuery));

/**
 * Unstyled Expandable Content
 *
 * Allows for a keyboard accessible and screen reader friendly expandable/collapsible
 * content section.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.unstyledExpandableContent = {
  defaultAnimation: {
    duration: 300,
    easing: "easeInOutQuart"
  },
  instances: [],
};

(function (component, $) {
  /**
   * DOM-ready component initialization.
   */
  component.init = function () {
    // Automatically initialize all elements with a `data-unstyled-expandable-content`
    // attribute.
    $('[data-unstyled-expandable-content]').each(component.initInstance);

    // Handle automatic collapse/hide based on media query.
    component.onViewportResize();
    // Handle window resize/orientationchange event. Debounced for performance.
    $(window).on('resize.unstyled-expandable-content orientationchange.unstyled-expandable-content', () => {
      $.doTimeout(100, component.onViewportResize)
    });
  };

  /**
   * Initialize a single component instance.
   */
  component.initInstance = function () {
    const instance = {};
    instance.$controlElement = $(this);
    instance.sectionId = instance.$controlElement.data('unstyledExpandableContent');
    instance.$expandableContent = $('#' + instance.sectionId);
    instance.toggleMode = instance.$controlElement.data('toggleMode') || 'slideToggle';
    instance.hideOnInit = instance.$controlElement.data('hideOnInit') !== false;
    instance.hideMediaQuery = instance.$controlElement.data('hideMediaQuery');
    instance.animationDuration = instance.$controlElement.data('animationDuration');
    instance.animationEasing = instance.$controlElement.data('animationEasing');
    instance.animation = $.extend({}, component.defaultAnimation, {
      duration: instance.animationDuration,
      easing: instance.animationEasing
    });

    // To keep track of all component instances.
    component.instances.push(instance);

    // If we should hide with CSS, add a modifier class.
    if (instance.toggleMode === 'hideWithCss') {
      instance.$expandableContent.addClass('unstyled-expandable-content__content--hide-with-css');
    }

    // Handle automatic hide on page load.
    if (instance.hideOnInit) {
      // If using slideToggle or hideWithCss, hide it initially (without animation).
      if (instance.toggleMode === 'slideToggle' || instance.toggleMode === 'hideWithCss') {
        component.toggleInstance(instance, false, false);
      }
    }

    // Add click handler for the control element (probably a button).
    instance.$controlElement.on('click.unstyled-expandable-content', function () {
      component.toggleInstance(instance);
    });
  };

  /**
   * Toggles an instance (expanded or collapsed).
   * Supports aria attributes for assistive technology.
   * @param {object} instance
   * @param {boolean} state
   * @param {boolean} useAnimation
   */
  component.toggleInstance = function (instance, state, useAnimation) {
    // If we are toggling the current expanded state...
    if (typeof state === 'undefined') {
      state = !component.isInstanceExpanded(instance);
    }

    // Switch the states of aria-expanded and aria-hidden
    instance.$controlElement.attr('aria-expanded', state);
    instance.$expandableContent.attr('aria-hidden', !state);

    // If using slide toggle, use transition-height helper to toggle its visibility.
    if (instance.toggleMode === 'slideToggle') {
      // Set up parent and inner classes required by helper.
      // Adding this check avoids duplicated parent wrappers.
      if (!instance.$expandableContent.parent('.transition-height').length) {
        instance.$expandableContent.wrap('<div class="transition-height"></div>');
      }
      instance.$expandableContent.addClass('transition-height__inner');
      // Toggle is-open.
      state ?
        instance.$expandableContent.addClass('is-open') :
        instance.$expandableContent.removeClass('is-open');
    }
  };

  /**
   * Returns whether the instance is expanded or collapsed.
   * @param {object} instance
   * @returns {boolean}
   */
  component.isInstanceExpanded = function (instance) {
    return instance.$controlElement.attr('aria-expanded') === 'true';
  }

  /**
   * Handle window resizes and switching between portrait/landscape mode.
   */
  component.onViewportResize = function () {
    component.instances.forEach(function (instance) {
      if (instance.hideMediaQuery && window.matchMedia) {
        const mediaQueryMatch = window.matchMedia(instance.hideMediaQuery).matches;
        if (instance.lastMediaQueryMatch !== mediaQueryMatch) {
          component.toggleInstance(instance, !mediaQueryMatch, false);
        }
        instance.lastMediaQueryMatch = mediaQueryMatch;
      }
    })
  };

  $(document).ready(component.init);
}(Components.unstyledExpandableContent, jQuery));

/**
 * Components.DropdownNav is a jQuery friendly plugin with an exposed JS API.
 * `component` is an alias for Components.DropdownNav object, which doubles as the
 * constructor function.
 *
 * State classes:
 *   .is-open - the component when expanded / open.
 *
 * On DOM-ready, all elements with the `dropdown-nav` class will automatically be
 * instantiated.
 *
 * Initialize yourself using jQuery `.tabDropdownNav()`:
 *   $('.my-element').tabDropdownNav();
 *
 * API Examples:
 *   Components.DropdownNav.closeAll()
 *
 *   var myDropdownNav = $('.element')[0].DropdownNav;
 *   myDropdownNav.close();
 *   myDropdownNav.open();
 *
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a constructor and base object for this component's data and functions.
Components.DropdownNav = function (element, options) {
  // Set up internal properties.
  this.defaultOptions = {};
  this.$element = $(element);

  // Use the init options.
  this.options = $.extend({}, this.defaultOptions, options);

  // Initialize this instance.
  this.init();
};

// Closure to encapsulate and provide the component object and jQuery in the local scope.
(function (DropdownNav, $) {

  /**
   * Component state and variables.
   */

  // Public
  DropdownNav.instances = [];

  // Private
  var jQueryPluginName = 'tabDropdownNav';
  var isDesktop = Components.utils.breakpoint('desktop');

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  DropdownNav.ready = function () {
    // Initialize every instance on the page.
    $('.dropdown-nav').tabDropdownNav();

    // Close all instances when clicking "outside" or when ESC key is pressed.
    $(document).on('click.dropdownNav', function (e) {
      DropdownNav.closeAll();
    })
    .on('keydown.dropdownNav', function (e) {
      if (e.keyCode === 27) {
        DropdownNav.closeAll();
      }
    });
  };

  /**
   * Event handler for viewport resize / orientation change.
   */
  DropdownNav.onViewportResize = function () {
    // Update internal state about whether this is a desktop+ sized viewport.
    isDesktop = Components.utils.breakpoint('desktop');
  };

  /**
   * Event handler for mouse pointer hovering over an element.
   */
  DropdownNav.onFocusOrBlur = function (e) {
    var $dropdownNav = this.$element;

    // Only for Desktop mode since we handle click events on mobile/tablet separately.
    if (!isDesktop) {
      return;
    }

    if (e.type === 'focus') {
      DropdownNav.closeAll();
      $dropdownNav.addClass('is-open');
    }
    else if (e.type === 'blur') {
      // Schedule a blur-triggered closing after a short delay.
      $dropdownNav.doTimeout('blur', 400, function () {
        // Only close if there are no focused links/buttons within.
        $dropdownNav.not($dropdownNav.has(':focus')).removeClass('is-open');
      });
    }
  };

  /**
   * Event handler for mouse pointer hovering over an element.
   */
  DropdownNav.onHoverOver = function () {
    var $dropdownNav = this.$element;
    $dropdownNav.doTimeout('open', 200, function () {
      if (isDesktop) {
        $dropdownNav.addClass('is-open');
      }
    });
  };

  /**
   * Event handler for mouse pointer leaving (un-hovering) an element.
   */
  DropdownNav.onHoverOut = function () {
    var $dropdownNav = this.$element;
    $dropdownNav.doTimeout('open', 200, function () {
      if (isDesktop) {
        $dropdownNav.removeClass('is-open');
      }
    });
  };

  /**
   * Initialize the component (jQuery plugin).
   */
  DropdownNav.prototype.init = function () {
    var $dropdownNav = this.$element;
    var $dropdownNavBody = $dropdownNav.find('.dropdown-nav__body');
    var $dropdownNavToggle = $dropdownNav.find('.dropdown-nav__toggle');
    var $dropdownNavLinks = $dropdownNav.find('.dropdown-nav__menu-link');

    // Register resize, orientationchange event handlers with debounce to
    // run logic at a more reasonable rate.
    $(window).on('resize.dropdownNav orientationchange.dropdownNav', () => {
      $dropdownNav.doTimeout(100, DropdownNav.onViewportResize)
    });

    // Handle clicks on the body element. Prevent bubbling up to the document.
    $dropdownNavBody.on('touchstart.dropdownNav', function (e) {
      e.stopPropagation();
    });

    // Register focus, blur event handlers on toggle element.
    $dropdownNavToggle.add($dropdownNavLinks).on('focus.global-header blur.global-header', DropdownNav.onFocusOrBlur.bind(this));

    // Register click event handlers on toggle element.
    $dropdownNavToggle.on('click.dropdownNav', function (e) {
      // Prevent bubbling up which results in a closeAll().
      e.stopPropagation();
      let expanded = false;
      const toggle = $dropdownNav.find('.dropdown-nav__toggle');

      if (!isDesktop) {
        $dropdownNav.toggleClass('is-open');
      }

      if ($dropdownNav.hasClass('is-open')) {
        expanded = true;
      }

      toggle.attr('aria-expanded', expanded);
    });

    // Handling for hover interaction of dropdown navs.
    //
    // Uses the doTimeout jQuery utility to handle throttling and waiting on a small delay
    // before showing the drawer (essentially hoverintent).
    $dropdownNav.hover(DropdownNav.onHoverOver.bind(this), DropdownNav.onHoverOut.bind(this));

    // Append to our instances array.
    DropdownNav.instances.push($dropdownNav);
  };

  /**
   * Open a specific instance.
   */
  DropdownNav.prototype.open = function () {
    this.$element.addClass('is-open');
  };

  /**
   * Close a specific instance.
   */
  DropdownNav.prototype.close = function () {
    this.$element.removeClass('is-open');
  };

  /**
   * Close all open instances, e.g., on blur.
   */
  DropdownNav.closeAll = function () {
    $.each(DropdownNav.instances, function (index, $dropdownNav) {
      $dropdownNav.removeClass('is-open');
    });
  };

  // Lightweight constructor, preventing against multiple instantiations
  $.fn[jQueryPluginName] = function (options) {
    return this.each(function initPlugin() {
      // Prevent multiple instantiations.
      if (this.DropdownNav) {
        return;
      }

      var plugin = new DropdownNav(this, options);

      // Store the plugin instance as a data property.
      $.data(this, 'plugin_' + jQueryPluginName, plugin);

      // Expose the plugin so methods can be called externally
      //   e.g., element.DropdownNav.close();
      this.DropdownNav = plugin;

      // Trigger custom initialized event on the element.
      $(this).trigger('initialized');
    });
  };

  // DOM-ready handler.
  $(DropdownNav.ready);

}(Components.DropdownNav, jQuery));

(function ($) {
  /**
   * Callback function to insert the menu into the DOM.
   */
  window.tabAjaxMenu = function (data) {
    var commands = {
      insert: function (response) {
        $(response.selector)[response.method](response.data);
      }
    };

    // Execute our commands.
    for (var i in data) {
      if (data[i]['command'] && commands[data[i]['command']]) {
        commands[data[i]['command']](data[i]);
      }
    }

    // Trigger event when our menu has been loaded.
    $(document).trigger('tabAjaxMenu:ready');

    // Attach customer menu behaviors when it's ready.
    if (typeof $.fn.tabDropdownNav === 'function') {
      $('.dropdown-nav').tabDropdownNav();
    }
  };
})(jQuery);

/**
 * Global search bar interaction
 */
(function ($) {
  $(document).ready(function () {
    var $globalHeader = $('.global-header');
    var globalHeaderData = $globalHeader.data();
    var $searchWrapper = $('.global-header__search');
    var $searchInput = $('.global-header__search-input');
    var $searchClose = $('.global-header__search-close');

    // External sites can override the search submit to redirect to www.tableau.com's
    // search page, using the data-www-search="all" type data attribute.
    if (globalHeaderData && globalHeaderData.wwwSearch) {
      $searchInput.on('submit-search.global-header-search', function () {
        window.location = 'https://www.tableau.com/search/' + globalHeaderData.wwwSearch + '/' + encodeURIComponent($(this).val());
      })
      .on('keydown.global-header-search', function (e) {
        if (e.keyCode === 13) {
          $(this).trigger('submit-search');
        }
      })
    }

    // Bind the click event on the global-header, in case the target element is AJAX-loaded.
    $globalHeader.on('click.global-header-search', '.global-header__search-toggle', function (e) {
      e.stopPropagation();
      e.stopImmediatePropagation();
      e.preventDefault();
      $globalHeader.addClass('global-header--search-shown');

      // Nasty setTimeout to ensure search is visible before focusing the input.
      setTimeout(function () {
        // Make sure to focus the search field when opened.
        $searchWrapper.find('input[form="coveo-dummy-form"], input[type="search"]').focus();
      }, 200);
    });

    $searchClose.on('click.global-header-search', function (e) {
      e.stopPropagation();
      e.preventDefault();
      $globalHeader.removeClass('global-header--search-shown');
    });
  });
})(jQuery);

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-blog-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-blog-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-blog-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const navWrapperId = 'blog-nav';
    const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

    if (navWrapper) {
      navWrapper.classList.add("".concat(navWrapperId, "-processed"));
      const navButton = document.querySelector('.mobile-blog-nav-button');
      const body = document.querySelector('body');
      const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusableEl = focusableNavElements[0];
      const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

      init({
        navWrapperId: navWrapperId,
        navWrapper: navWrapper,
        navButton: navButton,
        body: body,
        firstFocusableEl: firstFocusableEl,
        lastFocusableEl: lastFocusableEl
      });
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
* DO NOT EDIT THIS FILE.
* See the following change record for more information,
* https://www.drupal.org/node/2815083
* @preserve
**/


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

(function () {

  /**
   * Wait for it.
   */
  const debounce = (fn, time) => {
    let timeout;

    return function() {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  }

  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Get siblings of element.
   *
   * @param {element} The element.
   */
  function getSiblings(elem) {
    // Setup siblings array and get the first sibling
    let siblings = [];
    let sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
    return siblings;
  }

  /**
   * Shows and hides the specified menu item's second level submenu.
   *
   * @param {element} topLevelMenuITem - the <li> element that is the container for the menu and submenus.
   * @param {boolean} [toState] - Optional state where we want the submenu to end up.
   */
  function toggleSubNav(topLevelMenuITem, toState) {
    const button = topLevelMenuITem.querySelector('.blog-nav__button-toggle, .blog-nav__menu-link--button');
    const state = toState !== undefined ? toState : button.getAttribute('aria-expanded') !== 'true';
    const siblings = getSiblings(topLevelMenuITem);

    if (state) {
      button.setAttribute('aria-expanded', 'true');
      topLevelMenuITem.querySelector('.blog-nav__menu').classList.add('is-active');

      siblings.forEach(function (el) {
        if (el.classList.contains('blog-nav__menu-item--has-children')) {
          let siblingsChildren = el.querySelectorAll('.blog-nav__menu');
          siblingsChildren.forEach((uls) => {
            uls.classList.remove('is-active')
          });

          siblingsChildren = el.querySelectorAll('.blog-nav__button-toggle');
          siblingsChildren.forEach((button) => {
            button.setAttribute('aria-expanded', 'false')
          });

        }
      });

    } else {
      button.setAttribute('aria-expanded', 'false');
      topLevelMenuITem.querySelector('.blog-nav__menu').classList.remove('is-active');
    }
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenuBlog() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');

    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.blog-nav__button-toggle, .blog-nav__menu-link--button, .blog-nav__menu-link--nolink');

      if (button) {
        button.removeAttribute('aria-hidden');
        button.removeAttribute('tabindex');
        button.addEventListener('click', function (e) {
          const topLevelMenuITem = e.currentTarget.parentNode;
          toggleSubNav(topLevelMenuITem);
        });
      }

      el.addEventListener('mouseenter', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, true), 1000);
        }
      });
      el.addEventListener('mouseleave', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, false), 1000);
        }
      });
      el.addEventListener('keydown', function (e) {
        if (e.key === 'Enter' || e.key === ' ' || e.code === 'Space') {
          if (isDesktopNav()) {
            if (e.target.getAttribute('aria-expanded') == 'true') {
              debounce(toggleSubNav(e.currentTarget, false), 1000);
            }
            else {
              debounce(toggleSubNav(e.currentTarget, true), 1000);
            }
          }
        }
      });
    });

    let secondaryLinksWithChildren = document.querySelectorAll('.blog-nav__menu-item--level-2.blog-nav__menu-item--has-children');
    secondaryLinksWithChildren.forEach(function (el) {
      el.addEventListener('mouseenter', function (e) {
        el.querySelector('.blog-nav__menu--level-3').style.left = el.parentNode.offsetWidth + 'px';
      });
    });

    // On hitting escape key, close all the subnavs.
    document.addEventListener('keyup', function (e) {
      if (e.keyCode === 27 && isDesktopNav()) {
        closeAllSubNav();
      }
    });
  }

  /**
   * Close all second level sub navigation menus.
   */
  function closeAllSubNav() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');
    secondLevelNavMenus.forEach(function (el) {
      toggleSubNav(el, false);
    });
  }

  /**
   * Checks if any sub navigation items are currently active.
   * @return {boolean} If sub nav is currently open.
   */
  function areAnySubNavsOpen() {
    const secondLevelNavMenus = document.querySelectorAll('.blog-nav__menu-item--has-children');
    let subNavsAreOpen = false;
    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.blog-nav__button-toggle');
      const state = button.getAttribute('aria-expanded') === 'true';

      if (state) {
        subNavsAreOpen = true;
      }
    });
    return subNavsAreOpen;
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenuBlog);
})();

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const navWrapperId = 'header-nav';
    const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

    if (navWrapper) {
      navWrapper.classList.add("".concat(navWrapperId, "-processed"));
      const navButton = document.querySelector('.mobile-nav-button');
      const body = document.querySelector('body');
      const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
      const firstFocusableEl = focusableNavElements[0];
      const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

      init({
        navWrapperId: navWrapperId,
        navWrapper: navWrapper,
        navButton: navButton,
        body: body,
        firstFocusableEl: firstFocusableEl,
        lastFocusableEl: lastFocusableEl
      });
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
* DO NOT EDIT THIS FILE.
* See the following change record for more information,
* https://www.drupal.org/node/2815083
* @preserve
**/


if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;

    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

(function () {

  /**
   * Wait for it.
   */
  const debounce = (fn, time) => {
    let timeout;

    return function() {
      const functionCall = () => fn.apply(this, arguments);

      clearTimeout(timeout);
      timeout = setTimeout(functionCall, time);
    }
  }

  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Get siblings of element.
   *
   * @param {element} The element.
   */
  function getSiblings(elem) {
    // Setup siblings array and get the first sibling
    let siblings = [];
    let sibling = elem.parentNode.firstChild;

    // Loop through each sibling and push to the array
    while (sibling) {
      if (sibling.nodeType === 1 && sibling !== elem) {
        siblings.push(sibling);
      }
      sibling = sibling.nextSibling
    }
    return siblings;
  }

  /**
   * Shows and hides the specified menu item's second level submenu.
   *
   * @param {element} topLevelMenuITem - the <li> element that is the container for the menu and submenus.
   * @param {boolean} [toState] - Optional state where we want the submenu to end up.
   */
  function toggleSubNav(topLevelMenuITem, toState) {
    const button = topLevelMenuITem.querySelector('.primary-nav__button-toggle, .primary-nav__menu-link--button');
    const state = toState !== undefined ? toState : button.getAttribute('aria-expanded') !== 'true';
    const siblings = getSiblings(topLevelMenuITem);

    if (state) {
      button.setAttribute('aria-expanded', 'true');
      topLevelMenuITem.querySelector('.primary-nav__menu').classList.add('is-active');

      siblings.forEach(function (el) {
        if (el.classList.contains('primary-nav__menu-item--has-children')) {
          let siblingsChildren = el.querySelectorAll('.primary-nav__menu');
          siblingsChildren.forEach((uls) => {
            uls.classList.remove('is-active')
          });

          siblingsChildren = el.querySelectorAll('.primary-nav__button-toggle');
          siblingsChildren.forEach((button) => {
            button.setAttribute('aria-expanded', 'false')
          });

        }
      });

    } else {
      button.setAttribute('aria-expanded', 'false');
      topLevelMenuITem.querySelector('.primary-nav__menu').classList.remove('is-active');
    }
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu2() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');

    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.primary-nav__button-toggle, .primary-nav__menu-link--button, .primary-nav__menu-link--nolink');

      if (button) {
        button.removeAttribute('aria-hidden');
        button.removeAttribute('tabindex');
        button.addEventListener('click', function (e) {
          const topLevelMenuITem = e.currentTarget.parentNode;
          toggleSubNav(topLevelMenuITem);
        });
      }

      el.addEventListener('mouseenter', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, true), 1000);
        }
      });
      el.addEventListener('mouseleave', function (e) {
        if (isDesktopNav()) {
          debounce(toggleSubNav(e.currentTarget, false), 1000);
        }
      });
    });

    let secondaryLinksWithChildren = document.querySelectorAll('.primary-nav__menu-item--level-2.primary-nav__menu-item--has-children');
    secondaryLinksWithChildren.forEach(function (el) {
      el.addEventListener('mouseenter', function (e) {
        el.querySelector('.primary-nav__menu--level-3').style.left = el.parentNode.offsetWidth + 'px';
      });
    });

    // On hitting escape key, close all the subnavs.
    document.addEventListener('keyup', function (e) {
      if (e.keyCode === 27 && isDesktopNav()) {
        closeAllSubNav();
      }
    });
  }

  /**
   * Close all second level sub navigation menus.
   */
  function closeAllSubNav() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');
    secondLevelNavMenus.forEach(function (el) {
      toggleSubNav(el, false);
    });
  }

  /**
   * Checks if any sub navigation items are currently active.
   * @return {boolean} If sub nav is currently open.
   */
  function areAnySubNavsOpen() {
    const secondLevelNavMenus = document.querySelectorAll('.primary-nav__menu-item--has-children');
    let subNavsAreOpen = false;
    secondLevelNavMenus.forEach(function (el) {
      const button = el.querySelector('.primary-nav__button-toggle');
      const state = button.getAttribute('aria-expanded') === 'true';

      if (state) {
        subNavsAreOpen = true;
      }
    });
    return subNavsAreOpen;
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu2);
})();

(function ($) {
  /**
   * Checks for mobile buttons.
   * This originally came from Drupal.olivero so we needed to improvise for the
   * current installation.
   */
  function isDesktopNav() {
    let navButtons = document.querySelector('.dropdown-navs--section-nav .mobile-buttons');
    return getComputedStyle(navButtons).getPropertyValue('display') === 'none';
  }

  /**
   * Checks if navWrapper contains "is-active" class.
   * @param {object} navWrapper
   *   Header navigation.
   * @return {boolean}
   *   True if navWrapper contains "is-active" class, false if not.
   */
  function isNavOpen(navWrapper) {
    return navWrapper.classList.contains('is-active');
  }

  /**
   * Opens or closes the header navigation.
   * @param {object} props
   *   Navigation props.
   * @param {boolean} state
   *   State which to transition the header navigation menu into.
   */
  function toggleNav(props, state) {
    const value = !!state;
    props.navButton.setAttribute('aria-expanded', value);

    if (value) {
      props.body.classList.add('js-overlay-active');
      props.body.classList.add('js-fixed');
      props.navWrapper.classList.add('is-active');
    }
    else {
      props.body.classList.remove('js-overlay-active');
      props.body.classList.remove('js-fixed');
      props.navWrapper.classList.remove('is-active');
    }
  }

  /**
   * Init function for header navigation.
   * @param {object} props
   *   Navigation props.
   */
  function init(props) {
    props.navButton.setAttribute('aria-controls', props.navWrapperId);
    props.navButton.setAttribute('aria-expanded', 'false');

    props.navButton.addEventListener('click', function () {
      toggleNav(props, !isNavOpen(props.navWrapper));
    });
    // Closes any open sub navigation first, then close header navigation.
    document.addEventListener('keyup', function (e) {
      if (e.key === 'Escape') {
        if (props.olivero && props.olivero.areAnySubNavsOpen()) {
          props.olivero.closeAllSubNav();
        }
        else {
          toggleNav(props, false);
        }
      }
    });

    // Focus trap.
    props.navWrapper.addEventListener('keydown', function (e) {
      if (e.key === 'Tab') {
        if (e.shiftKey) {
          if (document.activeElement === props.firstFocusableEl && !props.olivero.isDesktopNav()) {
            props.navButton.focus();
            e.preventDefault();
          }
        }
        else if (document.activeElement === props.lastFocusableEl && !props.olivero.isDesktopNav()) {
          props.navButton.focus();
          e.preventDefault();
        }
      }
    });
    window.addEventListener('resize', function () {
      if (isDesktopNav()) {
        toggleNav(props, false);
        props.body.classList.remove('js-overlay-active', 'js-fixed');
      }
    });
  }

  /**
   * Initialize the navigation JS.
   * This was modified by Palantir to include a functionalble calling for
   * triggering the menu.
   */
  function initializeMenu() {
    const contentWrapper = document.querySelector('.dropdown-navs--section-nav');
    if (contentWrapper) {
      const navWrapperId = contentWrapper.attributes.getNamedItem('id').value;
      const navWrapper = document.querySelector("#".concat(navWrapperId, ":not(.").concat(navWrapperId, "-processed)"));

      if (navWrapper) {
        navWrapper.classList.add("".concat(navWrapperId, "-processed"));
        const navButton = document.querySelector('.dropdown-navs--section-nav .mobile-nav-button');
        const body = document.querySelector(".dropdown-navs__content");
        const focusableNavElements = navWrapper.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        const firstFocusableEl = focusableNavElements[0];
        const lastFocusableEl = focusableNavElements[focusableNavElements.length - 1];

        init({
          navWrapperId: navWrapperId,
          navWrapper: navWrapper,
          navButton: navButton,
          body: body,
          firstFocusableEl: firstFocusableEl,
          lastFocusableEl: lastFocusableEl
        });
      }
    }
  }

  // Trigger this JS after HTML.
  $(document).one('tabAjaxMenu:ready', initializeMenu);
})(jQuery);

/**
 * Interactions for Section Nav component.
 */
(function($) {
  $(document).ready(function() {
    var $nav = $('.section-nav'),
        $title = $nav.find('.section-nav__title, .block__title'),
        $menu = $nav.find('.section-nav__menu, .menu-block-wrapper > ul.menu'),
        sticky,
        isNotDesktop = Components.utils.breakpoint('tablet') || Components.utils.breakpoint('mobile');

    if ($nav.length) {
      $menu.wrap('<div class="transition-height"></div>');
      $menu.addClass('transition-height__inner');

      // The desktop menu should always be open since there is no toggle behavior here.
      if (Components.utils.breakpoint('desktop')) {
        $menu.addClass('is-open');
      }

      // Ensure menu state adjusts as expected for mobile/tablet and desktop.
      $(window).on('resize', function() {
        if (Components.utils.breakpoint('desktop')) {
          $menu.addClass('is-open');
          isNotDesktop = false;
        } else {
          $nav.removeClass('is-open');
          $menu.removeClass('is-open');
          isNotDesktop = true;
        }
      })

      // Handle opening/closing menu on mobile/tablet.
      $title.on('click', function(e) {
        if (isNotDesktop) {
          // Toggle class on the nav for the arrow transform.
          $nav.toggleClass('is-open');
          // Toggle class on the nav for the height transition.
          $menu.toggleClass('is-open');
          e.preventDefault();
        }
      });

      // Handle opening/closing menu on mobile/tablet.
      $nav.on('click', function(e) {
        if (e.target === this && isNotDesktop) {
          // Toggle class on the nav for the arrow transform.
          $nav.toggleClass('is-open');
          // Toggle class on the menu for the height transition.
          $menu.toggleClass('is-open');
          e.preventDefault();
        }
      }).end()
      // There are cases where the links in a section nav do not actually link anywhere, so the
      // behavior here is to treat the link like an <option> element: clicking the link closes the nav.
      .find('a').on('click', function(e) {
        if (!$(this).attr('href') && isNotDesktop) {
          e.preventDefault();
          $nav.removeClass('is-open');
          $menu.removeClass('is-open');
        }
      });

      // Sticky nav on mobile/tablet
      if (!Components.utils.breakpoint('desktop')) {
        sticky = new Waypoint.Sticky({
          element: $nav
        });
      }
    }
  });
})(jQuery);

/**
 * Sidebar nav  interaction including scroll-aware highlighting
 */

(function($){
  $(document).ready(function(){
    var $subnav = $('.subnav'),
        $links = $subnav.find('.subnav__links'),
        $linksWrapper = $links.find('.subnav__links-wrapper'),
        $anchors = $('.anchor-link');

    if ($links.length && $anchors.length) {
      $anchors.waypoint({
        handler: function(direction) {
          var id = this.element.id;
          if (direction === 'down') {
            $links.find('a[href="#' + id + '"]').parent().addClass('is-active').siblings().removeClass('is-active');
          } else if (direction === 'up') {
            $links.find('a[href="#' + id + '"]').parent().prev().addClass('is-active').siblings().removeClass('is-active');
          }
        },
        offset: $subnav.outerHeight(true)
      });

      // Handle scrolling of links on mobile if they are present.
      if ($linksWrapper.length) {
        mobileScroll();
        $(window).on('resize orientationchange', () => {
          $.doTimeout(100, mobileScroll);
        });
      }
    }

    // Manage scroll fading on mobile if there's overflow.
    function mobileScroll() {
      var width = $linksWrapper[0].offsetWidth,
          scrollWidth = $linksWrapper[0].scrollWidth;

      if (width < scrollWidth) {
        // Add right fade right away since we always start on the left.
        $links.addClass('fade-right');

        $linksWrapper.scroll(function () {
          var scrollPos = $linksWrapper.scrollLeft();

          // Add both fades and then remove below if needed.
          $links.addClass('fade-right fade-left');

          // Remove right fade when scrolled all the way to the right
          if (scrollPos === (scrollWidth - width)) {
            $links.removeClass('fade-right');
          }
          // Remove left fade when scrolled all the way to the left
          if (scrollPos === 0) {
            $links.removeClass('fade-left');
          }
        });
      } else {
        $links.removeClass('fade-left fade-right');
      }

    }
  });
})(jQuery);

(function ($) {
  // Bind to document ready and custom components:reattach event so this works on
  // dynamically loaded AJAX content.
  $(document).on('ready components:reattach', function (e, context) {

    /**
     * Handles making the whole row clickable
     * Makes the assumption that there exists exactly one .table-list__link in a
     * --clickable-row
     */
    $('.table-list--clickable-row tbody tr')
    .off('click.tableList')
    .on('click.tableList', function (e) {
      const $tableLink = $(this).find('.table-list__link a');
      const selection = window.getSelection();

      // Handle clicking non-anchor/button elements.
      if (e.target.nodeName !== 'A' && e.target.nodeName !== 'BUTTON') {
        // Abort handler if a non-empty text selection exists since the user was
        // probably trying to copy some text inside the table list row.
        if (selection && selection.toString().length) {
          return false;
        }

        // Default action is to trigger the tableLink at this point.
        if ($tableLink.length) {
          $tableLink.get(0).click();
        }
      }
    });
  });
})(jQuery);

/**
 * Loading overlay behaviors.
 *
 * - Show a loading animation w/ overlay.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.loadingOverlay = {};

// Closure to rename Components.modalMessage
(function (component, $) {

  /**
   * Show loading overlay
   *
   * @param {string} $element
   *   The element on which you want to show the loading animation.
   * @param {string} message
   *   Optional message to display in the loading overlay.
   */
  component.show = function ($element, message, modifier) {
    var message = message || 'Loading...',
        $overlay = $('<div class="loading-overlay">' +
          '<div class="loader">' +
          '<div class="loader__animation"></div>' +
          '<div class="loader__message">' + message + '</div>' +
          '</div>' +
          '</div>'),
        offsetY = Components.utils.getElementViewPortCenter($element);

    // Allow custom modifier.
    if (modifier) {
      $overlay.addClass(modifier);
    }

    $overlay.find('.loader').css('top', offsetY);
    $overlay.prependTo($element)
  };

  /**
   * Hide loading overlay
   *
   * @param {string} $element
   *   The element on which you want to show the loading animation.
   * @param {int} delay
   *   Delay in milliseconds.
   */
  component.hide = function ($element, delay) {
    // Set default to 0 ms.
    delay = delay || 0;

    setTimeout(function () {
      $element.find('.loading-overlay').remove();
    }, delay);
  };

}(Components.loadingOverlay, jQuery));

/**
 * Scroll Reveal component interaction
 * See https://github.com/jlmakes/scrollreveal for documentation
 */

(function ( $ ) {
  $(document).ready(function(){
    var sequenceIntervalDefault = 100,
        movementDistance = '200px',
        defaultSettings = {
          scale: 1,
          distance: 0,
          duration: 1200,
          easing: 'cubic-bezier(0.77, 0, 0.175, 1)'
        },
        origins = ['left', 'right', 'top', 'bottom'];

    // Initiate Scroll Reveal with some default settings.
    window.scrollReveal = ScrollReveal(defaultSettings);

    // Presets for the different origin modifiers (moving into place from
    // specified direction).
    for (var i = 0; i < origins.length; i++) {
      scrollReveal.reveal('.scroll-reveal--' + origins[i], {
        origin: origins[i],
        distance: movementDistance
      });

      // Because these elements can potentially hang off the side of the page
      // and cause horizontal scrolling, we have to hide overflow on section
      // wrappers.
      $('.scroll-reveal--' + origins[i]).parents('.section').css('overflow-x', 'hidden');
    }

    // Sequenced reveals based on the default interval set above or an interval
    // specified in a data attribute on the container.
    $('.scroll-reveal__sequence-container').each(function() {
      var sequenceDelay = $(this).data('sequence-delay') || 0,
          sequenceInterval = $(this).data('sequence-interval') || sequenceIntervalDefault;

      $(this).find('.scroll-reveal--sequenced').each(function() {
        scrollReveal.reveal(this, {
          delay: sequenceDelay
        });
        sequenceDelay += sequenceInterval;
      });
    });

    // Apply any options applied via data attributes
    $('.scroll-reveal').each(function() {
      scrollReveal.reveal(this, $(this).data());
    });
  });
}( jQuery ));

/** 
 * Search Highlight utility.
 *
 * Searches through a list of items and highlights items that match the term.
 */
(function($){
  $(document).ready(function(){
    var $searches = $('.search-highlight input[type="search"]');
    
    if ($searches.length) {
      $searches.each(function(index, el) {
        var $search = $(el),
            $content = $('#' + $search.data('content')),
            highlightClass = $search.data('highlight-class') + " search-highlight__match",
            $contentItems = $content.find('li');

        $search.on('change paste keyup search', function(e) {
          var term = $(this).val().toLowerCase();
          $contentItems.each(function(index, item) {
            var text = $(item).text().toLowerCase();
            $(item).removeClass(highlightClass);
            if (term.length > 0 && text.indexOf(term) > -1) {
              $(item).addClass(highlightClass);
            }
          });
        });

      });
    }
  });
})(jQuery);

(function ($) {
  $.fn.sonarPulse = function (options) {
    var $el = $(this),
        defaults = {
          // sonarSelector {String}
          // CSS selector identifying the sonar element
          sonarSelector: '.sonar-indicator',

          // sonarElement {jQuery}
          // jQuery object containing the sonar element
          $sonarElement: $('<div class="sonar-indicator"></div>'),

          // timeout {Number}
          // milliseconds before removing the element matching the sonarSelector option
          timeout: 5000,

          // offset {Number} (deprecated)
          // a negative pixel offset from the element's X position
          offset: 5,

          // top/right/bottom/left {String}
          // position properties applied to the sonar element
          top:    '0',
          right:  '0',
          bottom: '0',
          left:   '0',

          // limitDisplayCount {Number}
          // uses localStorage to limit the number of displays (disabled when 0)
          limitDisplayCount: 0,

          // limitDisplayId {String|null}
          // a unique identifier for tracking the display count
          limitDisplayId: null
        },
        padding,
        hasLocalStorage,
        displayCount;

    // Handle deprecated options.
    if (options) {
      // Handle deprecated offset option.
      if (options.offset) {
        padding = parseInt($el.css('padding-left').replace('px', ''));
        // left replaces offset, ignoring padding, and negated.
        options.left = ((padding / 2) - options.offset) + 'px';
      }
    }

    // Extend defaults without overwriting them.
    options = $.extend({}, defaults, options);

    // Limit displays by count using localStorage API. Ignore if localStorage is not supported.
    hasLocalStorage = window.localStorage
      && typeof localStorage.getItem === 'function'
      && typeof localStorage.setItem === 'function';

    // Check localStorage support and if we are limiting the display count
    if (hasLocalStorage && options.limitDisplayId && options.limitDisplayCount > 0) {
      displayCount = localStorage.getItem('sonarPulseCount_' + options.limitDisplayId) || 0;
      // Stop here if we display count is greater than or equal to the allowed count.
      if (displayCount >= options.limitDisplayCount) {
        return;
      }
      // Save the latest value to localStorage.
      localStorage.setItem('sonarPulseCount_' + options.limitDisplayId, ++displayCount);
      // Trigger a custom event so other JS can do stuff.
      $el.trigger('sonar:activate');
    }

    // Apply positioning to the sonar element.
    options.$sonarElement.css({
      top: options.top,
      right: options.right,
      bottom: options.bottom,
      left: options.left
    });

    // Add the sonar element, ensuring only one exists.
    $el.remove(options.sonarSelector).prepend(options.$sonarElement);

    // If timeout is non-zero, remove after delay.
    if (options.timeout > 0) {
      // Remove our sonar pulse after 5 seconds.
      setTimeout(function () {
        $el.find(options.sonarSelector).remove();
        // Trigger a custom event so other JS can do stuff.
        $el.trigger('sonar:deactivate');
      }, options.timeout);
    }
  };

  // Auto-initialize on DOM ready with .sonar-pulse class.
  // Provide options using data-sonar-options attribute and JSON string:
  // data-sonar-options='{"sonarSelector": ".sonar-indicator", "timeout": 5000, "offsetY": "-10", "limitDisplayCount": 1, "limitDisplayId": "cool-thing"}'
  $(function () {
    $('.sonar-pulse').each(function () {
      var $el = $(this);

      $el.sonarPulse($el.data('sonarOptions') || {});
    });
  })
}(jQuery));

(function($) {
  $(document).ready(function() {

    /**
     * Allows making an element sticky on the page with just a 'sticky' class.
     */
    $('.sticky').each(function(i) {
      stickIt(this);
    });

    // For less capable browsers, only execute sticky on desktop.
    if (!window.matchMedia || $('.lt-ie9').length) {
      $('.sticky--desktop').each(function(i) {
        stickIt(this);
      });
      return;
    }

    if (Components.utils.breakpoint('desktop')) {
      $('.sticky--desktop').each(function(i) {
        stickIt(this);
      });
    }

    if (Components.utils.breakpoint('tablet')) {
      $('.sticky--tablet').each(function(i) {
        stickIt(this);
      });
    }

    if (Components.utils.breakpoint('mobile')) {
      $('.sticky--mobile').each(function(i) {
        stickIt(this);
      });
    }
  });

  function stickIt(el) {
    var sticky = new Waypoint.Sticky({
      element: el
    });
  }
})(jQuery);

/**
 * Thumbnail colors.
 */

// Loose augmentation pattern. Creates top-level Components variable if it
// doesn't already exist.
window.Components = window.Components || {};

// Create a base for this module's data and functions.
Components.thumbnail = {};

// Closure to extend behavior, provide privacy and state.
(function (component, $) {
  var i = -1;

  // Available thumbnail colors.
  // @See $thumbnail-colors SASS variable.
  component.colors = ['dark-blue', 'teal', 'light-blue', 'light-orange', 'red', 'yellow'];

  /**
   * DOM-ready callback.
   *
   * @param {Object} $
   *   jQuery
   */
  component.ready = function ($) {
    // Initialize unique color for each thumbnail.
    $('.thumbnail--color').not('[class*="thumbnail--color-"]').each(function () {
      $(this).addClass('thumbnail--color-' + component.pickUniqueColor());
    });
  };

  /**
   * Pick a unique color.
   */
  component.pickUniqueColor = function () {
    i++;

    // If no choices are left, start back from the beginning.
    if (i < 0 || i === component.colors.length) {
      i = 0;
    }

    // Pick a color.
    return component.colors[i];
  };

})(Components.thumbnail, jQuery);

// Attach our DOM-ready callback.
jQuery(Components.thumbnail.ready);
